:root {
    
    --radio-switch-width: 219px;
    --radio-switch-height: 39px;
    --radio-switch-padding: 3px;
    --radio-switch-radius: 50em;
  
    
    --radio-switch-animation-duration: 0.3s;
  }
  
  .radio-switch {
    display: inline-flex;
    padding: var(--radio-switch-padding);
    border-radius: var(--radio-switch-radius);
    border: 1px solid rgba(22, 94, 91, 0.2);
    background: #F4FBFA;
  }
  
  .radio-switch__item {
    height: calc(var(--radio-switch-height) - 2*var(--radio-switch-padding));
    width: calc(var(--radio-switch-width)*0.5 - var(--radio-switch-padding));
    list-style-type: none;
    position: relative;
  }

  .radio-switch__item input {
    -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
    position: relative;
  }
  
  .radio-switch__label {
    display: block;
    line-height: calc(var(--radio-switch-height) - 2*var(--radio-switch-padding));
    text-align: center;
    border-radius: var(--radio-switch-radius);
    font-weight: 500;
    font-size: 16px;
    color: #000;
    position: absolute;
    top: 0;
    left: 0;
    height: calc(var(--radio-switch-height) - 2*var(--radio-switch-padding));
    width: calc(var(--radio-switch-width)*0.5 - var(--radio-switch-padding));
    z-index: 1;
  }

  .radio-switch__marker {
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background-color: var(--bg-primary);
    border-radius: var(--radio-switch-radius); 
    transition: transform var(--radio-switch-animation-duration);
  }

  .radio-switch__input:checked ~ .radio-switch__marker { 
    transform: translateX(100%);
  }

  .radio-switch__input:checked ~ .radio-switch__label {
    color: #fff;
  }