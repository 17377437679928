
.faq {
    padding: 50px 0px 80px 0px;
    min-height: calc(100vh - 70px);
}

.faq h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 10px;
}

.faq p {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: rgba(73, 73, 73, 0.7);
    margin-bottom: 35px;
}

.faq .accordion .accordion-item {
    background: #FFFFFF;
    border: 1px solid rgba(22, 94, 91, 0.4);
    border-radius: 5px;
    margin-bottom: 16px;
}

.faq .accordion .accordion-item .accordion-header button {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    padding: 19px 40px 19px 24px;
    color: #000;
}

.faq .accordion .accordion-item .accordion-header button:focus, 
.faq .accordion .accordion-item .accordion-header button:active {
    outline: none;
    box-shadow: none;
}

.faq .accordion .accordion-item .accordion-header button::after {
    background: url('../../images/icons/plus.svg') no-repeat;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.faq .accordion .accordion-item .accordion-header button:not(.collapsed)::after {
    background: url('../../images/icons/minus.svg') no-repeat center;
}

.faq .accordion .accordion-item .accordion-body {
    padding: 25px 24px;
}

.faq .accordion .accordion-item .accordion-body p {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #494949;
    margin-bottom: 5px;
}

