
:root {
    --body-bg: #F0F2F5;
    --bg-white: #FFFFFF;
    --bg-primary: #165E5B;
    --bg-primery-text: #fff;
    --default-text-color: #000000;
    --light-text: #494949;
    --default-border-color: #D9D9D9;
    --active-chat-border-color: #165E5B66;
    --received-bg: #E9F2FF;
    --send-bg: #BCF1D5;
    --default-radius: 5px;
    --bg-body: #ECF1F9;

}

@font-face {
    font-family: Segoe UI;
    font-weight: 400;
    src: url('../css/fonts/SegoeUI.ttf');
  }

  @font-face {
    font-family: Segoe UI;
    font-weight: 500;
    src: url('../css/fonts/SegoeUISemibold.ttf');
  }
  
  @font-face {
    font-family: Segoe UI;
     font-weight: 600;
    src: url('../css/fonts/SegoeUIBold.ttf');
  }

html,body {
    padding: 0;
    margin: 0;
}

body {
    background-color: var(--bg-body);
    font-family: 'Segoe UI', sans-serif ;
}

p {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.btn {
    border-radius: var(--default-radius);
    border: 1px solid;
}

.main {
    background-color: var(--body-bg);
}

.btn {
    font-family: 'Segoe UI';
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    padding: 11px 20px;
}

.btn-main {
    background: #165E5B;
    border: 1px solid #165E5B;
    border-radius: 5px;
    color: #fff;
}

.btn-main-default {
    background-color: transparent;
    border: 1px solid #165E5B;
    border-radius: 5px;
    color: #165E5B;
}

.btn-main:hover, 
.btn-check:checked+.btn, 
.btn.active, .btn.show, 
.btn:first-child:active, 
:not(.btn-check)+.btn:active {
    background-color: transparent;
    border: 1px solid #165E5B;
    color: #165E5B;
}

.btn-main-default:hover {
    background: #165E5B;
    border: 1px solid #165E5B;
    color: #fff;
}

.btn-main:hover svg path {
    stroke : #165E5B !important;
}

input.form-control:focus, input.form-control:active, 
select.form-select:focus, select.form-select:active {
    outline: none;
    box-shadow: none;
}

.inner-main {
    background-color: var(--bg-body);
}

.template-list-sec {
    width: 100%;
    max-width: 850px;
    margin: 25px auto;
    padding: 20px;
    border-radius: 5px;
}

.contact-group-top {
    padding-top: 30px;
    padding-bottom: 30px;
}

.contact-group-top  .btn-main, 
.contact-group-top  .btn-main-default {
    padding: 13px 20px;
    font-weight: 500;
    border-radius: var(--default-radius);
}

.contact-group-top  .btn-main i {
    font-size: 20px;
}

.whatsapp-modal .modal-content {
    padding: 20px 30px 30px 30px;
}

.whatsapp-modal .modal-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
}

.whatsapp-modal .modal-header .modal-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 18px;
    position: relative;
}

.whatsapp-modal .modal-header .modal-title .back-btn {
    color: #000;
    position: absolute;
    top: 2px;
    left: 0;
    cursor: pointer;
}

.whatsapp-modal .modal-header .modal-title .finish-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: var(--bg-primary);
    float: right;
    cursor: pointer;
}

.whatsapp-modal .modal-header .btn-close {
    opacity: 1;
    font-size: 12px;
    margin-bottom: 10px;

}

.whatsapp-modal .modal-body {
    padding: 20px 0px;
}

.whatsapp-modal .modal-body .row {
    margin-bottom: 20px;
}

.whatsapp-modal .modal-body .row:last-child {
    margin-bottom: 0;
}

.whatsapp-modal .modal-body .row .form-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000;
    width: 100%;
}

.whatsapp-modal .modal-body .row .form-control, 
.whatsapp-modal .modal-body .row .form-select {
    background-color: rgba(232, 239, 239, 0.5);
    border: none;
    border-radius: var(--default-radius);
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    padding: 12px 8px;
    color: #494949;
}

.whatsapp-modal .modal-body .row .form-control.search {
    background-color: #fff;
    border: 1px solid #0000001A;
    border-radius: var(--default-radius);
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    padding: 12px 8px;
    color: #494949;
}

.whatsapp-modal .modal-body .row .form-control.file-up {
    border: 1px solid var(--bg-primary);
    background-color: #fff;
    border-radius: 5px;
    height: 35px;
    padding: 0.375rem 0.75rem;
    color: var(--bg-primary);
    font-weight: 500;
}

.whatsapp-modal .modal-body .row .input-label {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 35px;
    padding: 0.375rem 0.75rem;
    color: var(--bg-primary);
    font-weight: 500;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    background: #E8EFEF;
    border: 1px solid #165E5B;
    cursor: pointer;
}

.whatsapp-modal .modal-header .modal-title .import-cvs,
.whatsapp-modal .modal-body .row .import-cvs {
    background-color: transparent;
    border: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    padding: 0;
    color: #165E5B;
}

.whatsapp-modal .modal-footer {
    border-top: none;
    padding-bottom: 0;
}

.whatsapp-modal .modal-footer .btn {
    padding: 14px 30px;
}

.whatsapp-modal .modal-footer .btn-danger-light {
    background: rgba(233, 75, 66, 0.1);
    border-color: rgba(233, 75, 66, 0.1);
    color: #C6281F;
}

.modal-csv .modal-header .modal-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000;
}

.modal-csv .modal-header .modal-title p {
    color: var(--light-text);
    margin-top: 8px;
    margin-bottom: 0;
}

.modal-csv .download-template {
    background-color: #165E5B;
    border: 1px solid #D9D9D999;
    border-radius: 5px;
    padding: 16px 16px 25px 16px;
    color: #FFFFFF;
}

.modal-csv .download-template p {
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 20px;
}

.modal-csv .download-template .btn.btn-main {
    box-shadow: 0px 1px 7.3px 0px #0000001A;
    border: 1px solid #FFFFFF;
    background-color: #fff;
    color: #165E5B;
}

.modal-csv .Upload-cvs-file {
    position: relative;
    border: 1px solid #BDC8D4;
    width: 100%;
    border-radius: 5px;
    padding: 10px 20px;
    text-align: center;
    margin-top: 20px;
}

.modal-csv .Upload-cvs-file input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.modal-csv .Upload-cvs-file .file-body {
    font-size: 15px;
    font-weight: 500;
    line-height: 19.95px;
    color: #4B4A4A;
    width: 100%;
}

.modal-g-contact .modal-header {
    border-bottom: 0px;
}

.modal-g-contact .modal-header .btn-close {
    background-size: 11px;
    opacity: 1;
}

.radio-row label {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.radio-row .form-check-input {
    border: 2px solid rgba(0, 0, 0, 0.6);
    width: 22px;
    height: 22px;
}

.radio-row .form-check-input:checked {
    background-color: var(--bg-primary);
    border-color: var(--bg-primary);
}

.form-check-input:focus, 
.form-check-input:active {
    outline: none;
    box-shadow: none;
}

.main.edit-profile {
    background-image:  url('../images/backgrounds/form-bg.svg');
    background-color: var(--body-bg);
    background-repeat: no-repeat;
    background-position: center ;
    background-size: 100%;
    padding-top: 45px;
    padding-bottom: 120px;
}

.form-container {
    background-color: var(--bg-white);
    border-radius: 12px;
    width: 100%;
    max-width: 595px;
    margin: 0 auto;
    box-shadow: 0px 20px 43px rgba(22, 94, 91, 0.16);
}

.form-container .form-header {
    padding: 25px 40px;
    border-bottom: 1px solid rgba(22, 94, 91, 0.15);
}

.form-container .form-header ul li .profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--bg-primary);
    background-image: url('../images/icons/edit-profile-bg.svg');
    background-position: center;
    background-repeat: no-repeat;
}

.form-container .form-header ul li .profile-img img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.form-container .form-header ul li {
    padding-right: 25px;
}

.form-container .form-header ul li .profile-img-upload {
    position: relative;
}

.form-container .form-header ul li .profile-img-upload {
    border: 1px solid var(--bg-primary);
    color: var(--bg-primary);
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    border-radius: 5px;
}

.form-container .form-header ul li .profile-img-upload:hover {
    background-color: var(--bg-primary);
    color: var(--bg-primery-text);
}

.form-container .form-header ul li .profile-img-upload input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.form-container .form-header ul li .action-list {
    display: flex;
}

.form-container .form-header ul li .action-list .action-btn {
    border: 1px solid var(--bg-primary);
    color: var(--bg-primary);
    font-size: 16px;
    line-height: 21px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    padding: 10px 10px;
    box-sizing: border-box;
}

.form-container .form-header ul li .action-list .action-btn:hover {
    background-color: var(--bg-primary);
    color: var(--bg-primery-text);
}

.form-container .form-body {
    padding: 27px 40px 40px 40px;
}

.form-container .form-title {
    margin-bottom: 20px;
}

.form-container .form-title h1 {
    color: var(--bg-primary);
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 5px;
}

.form-container .form-title p {
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.form-container .form-body .form-group {
    margin-bottom: 20px;
}

.form-container .form-body .form-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
}

.form-container .form-body input {
    background: #FFFFFF;
    border: 1px solid rgba(22, 94, 91, 0.2);
    border-radius: 5px;
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #494949;
}

.form-container .form-body input[type="email"] {
    pointer-events: none;
    background: #ECF0F0;
}

.form-container .form-body .btn-main {
    margin-top: 50px;
}

/* .broadcast-sec {
    background: #F4FBFA;
} */

.broadcast-sec .container-fluid {
    padding-left: 75px;
    padding-right: 75px;
}

.overview-heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
}

.template-heading {
    font-weight: 700;
    font-size: 22px;
    line-height: 21px;
}

.template-heading .badge {
    width: 28px;
    height: 28px;
    background: #F0F2F5;
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
    color: #000;
    margin-left: 15px;
}

.template-nav ul {
    margin-top: 20px;
    margin-bottom: 30px;
}

.template-nav ul li {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    padding: 15px 20px;
    border: 1px solid #165E5B;
    color: #4B4A4A;
    border-right: 0;
    cursor: pointer;
}

.template-nav ul li:hover, 
.template-nav ul li.active {
    background-color: #165E5B;
    color: #fff;
}

.template-nav ul li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.template-nav ul li:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: 1px solid #165E5B;
}

.template-lsit .container {
    background: #FFFFFF;
    border: 1px dashed rgba(119, 119, 119, 0.5);
    border-radius: 5px;
    padding-top: 17px;
    padding-bottom: 17px;
}

.template-lsit .container.nav-container {
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.template-lsit .container .single-template {
    background: rgba(240, 242, 245, 0.5);
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 18px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
    border: 1px solid transparent;
}

.template-lsit .container .single-template .template-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 6px;
}

.template-lsit .container .single-template .template-info .template-lang, 
.template-lsit .container .single-template .template-info .template-date-time {
    color: rgba(0, 0, 0, 0.6);
}

.template-lsit .container .single-template:hover {
    background: #E8EFEF;
    border: 1px solid rgba(22, 94, 91, 0.3);
}

.template-lsit .container .single-template .action ul li {
    padding: 0px 3px;
}

.template-lsit .container .single-template .action ul li .action-b {
    width: 32px;
    height: 29px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.template-header .template-heading a {
    color: var(--bg-primary);
    text-decoration: none;
}

.create-template-form {
    background: #FFFFFF;
    border: 1px dashed rgba(119, 119, 119, 0.5);
    border-radius: 5px;
    padding: 20px 26px;
}

.create-template-form .form-label, 
.contact-m-form .form-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 8px;
}

.create-template-form .label-bottom {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #494949;
    margin-bottom: 12px;
}

.create-template-form .form-control , 
.create-template-form .c-select .css-13cymwt-control, 
.create-template-form .c-select .css-t3ipsp-control, 
.contact-m-form .form-control , 
.contact-m-form .c-select .css-13cymwt-control, 
.contact-m-form .c-select .css-t3ipsp-control {
    background-color: #F3F7F7;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-bottom: 20px;
    border: none;
}

.css-1fdsijx-ValueContainer, 
.css-15lsz6c-indicatorContainer, .css-qbdosj-Input {
    padding: 0 !important;
}

.css-1fdsijx-ValueContainer .css-1dimb5e-singleValue, 
.css-qbdosj-Input {
    color: #494949;
    margin: 0 !important;
}

.css-1fdsijx-ValueContainer .css-1dimb5e-singleValue {
    margin: 0;
}

.create-template-form .c-select {
    max-width: 314px;
}

.create-template-form .c-select .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer 
span.css-1u9des2-indicatorSeparator, .css-1u9des2-indicatorSeparator {
    display: none !important;
}

.create-template-form .c-select .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer, 
.contact-m-form .c-select .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer {
    padding: 0;
}

.create-template-form textarea.form-conrol {
    height: auto;
}

.create-template-form .button-select .form-check {
    padding-left: 0;
    margin-bottom: 20px;
    margin-right: 2rem;
}

.create-template-form .button-select .form-check .form-check-input {
    margin-left: 0;
    padding: 5px;
    width: 18px;
    height: 18px;
    border: 1.5px solid #4B4A4A;
    border-radius: 50%;
}

.create-template-form .button-select .form-check .form-check-input:checked {
    background-color: #fff;
    border-color: var(--bg-primary);
    background-image: url('../images/check-bg-radio.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 8px;
}

.create-template-form .button-select .form-check label {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
}

.create-template-form .button-details {
    /* border: 1px solid rgba(22, 94, 91, 0.2);
    border-radius: 5px; */
    padding: 18px 0px;
}

.create-template-form .button-details .button-text {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.create-template-form .button-details .form-control, 
.create-template-form .button-details .c-select {
    max-width: 314px;
    margin-right: 15px;
    margin-bottom: 0;
}

.create-template-form .button-details .c-select .css-13cymwt-control, 
.create-template-form .button-details .c-select .css-t3ipsp-control {
    margin-bottom: 0 !important;
    padding: 8px 15px;
}

.create-template-form .button-details .close-input img {
    width: 12px;
    height: auto;
    cursor: pointer;
}

.create-template-form .add-btn {
    background: #fff;
    border-radius: 5px;
    color: #000;
    font-weight: 500;
    border: none;
    font-size: 14px;
}

.call-to-action-details .form-control, 
.call-to-action-details .c-select {
    max-width: 144px !important;
    margin-right: 7px !important;
}

.create-template-form .form-group {
    position: relative;
}

.create-template-form .form-group .input-body .form-control {
    padding-right: 70px;
}

.create-template-form .form-group span.word-limit {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #494949;
}

.create-template-form .form-group .textarea-body .form-control {
    padding-right: 70px;
}

.create-template-form .form-group .textarea-body span.word-limit {
    top: 12px;
    transform: none;
}

.preview-sec {
    padding: 35px 40px;
    background: url('../images/backgrounds/Rectangle.png') center no-repeat;
    background-size: cover;
    height: auto;
    /* min-height: 720px; */
    position: relative;
    border-radius: 5px;
}

.preview-sec .preview-body {
    background: #fff;
    border-radius: 5px;
    width: 100%;
    height: auto;
    position: relative;
}

.preview-sec .preview-body::before {
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    width: 0;
    height: 0;
    border-top: 12px solid #fff;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid transparent;
}

.preview-sec .preview-screen {
    padding: 13px 24px;
}

.preview-sec .preview-screen .heading, 
.preview-sec .preview-screen .footer {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 15px;
}

.preview-sec .preview-screen .timing {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 10px;
}

.preview-sec .preview-screen .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 15px;
    height: auto;
    max-height: calc(100vh - 80vh);
    overflow-y: auto;
}

.preview-sec .preview-body .link-footer {
    border-top: 1px solid #fff;
    /* padding: 16px 24px; */
    text-align: center;
    border-top: 1px solid #E9F2FF;
}

.preview-sec .preview-body .link-footer a {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #0FA6E0;
    margin-bottom: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
    padding: 15px 10px;
    border-right: 1px solid #E9F2FF;
    margin-bottom: 0;
}

.preview-sec .preview-body .link-footer a img {
    margin-right: 5px;
}

.preview-sec .preview-body .link-footer .col-xs-6:last-child a {
    border-right: 0;
}

.preview-sec .event-time {
    margin-top: 4px;
}

.preview-sec .event-time .e-time {
    background: #fff;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding: 12px;
    color: #0FA6E0;
}

.css-1fdsijx-ValueContainer, 
.css-hlgwow {
    padding: 0 !important;
}

.css-qbdosj-Input, 
.css-19bb58m {
    padding: 0 !important;
    margin: 0 !important;
}

.slect-indicator {
    color: var(--bg-primary);
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 12px;
}

.whatsapp-modal.preview-template .modal-header {
    border: none;
}

.whatsapp-modal.preview-template .modal-header .btn-close {
    margin-bottom: 0;
    padding-bottom: 0;
}

.whatsapp-modal.preview-template .modal-content {
    background: url('../images/backgrounds/Rectangle.png') center no-repeat;
    background-size: cover;
    max-width: 375px;
    border: 2px solid #FFFFFF;
    padding: 20px;
    margin: auto;
}

.whatsapp-modal .preview-sec {
    min-height: auto;
    background-image: none;
    padding: 10px 0px;
}


.placeholder {
    background-color: #b3b3b3 !important;
}


.accessfacebook-content {
    box-shadow: 0px 20px 43px rgba(22, 94, 91, 0.16);
    border-radius: 12px;
    padding: 35px 60px;
    color: #494949;
}

.accessfacebook-content .access-img img {
    max-width: 230px;
    height: auto;
}

.accessfacebook-content h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 20px;
}

.accessfacebook-content p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
}

.accessfacebook-content p a {
    color: #3578E4;
    text-decoration: none;
}

.accessfacebook-content p b {
    font-weight: 600;
}

.accessfacebook-content p.top {
    font-weight: 500;
    margin-bottom: 20px;
}

.accessfacebook-content .highlighted-pra {
    background: #FFF6E9;
    border: 1px solid #FED08F;
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 30px;
}

.accessfacebook-content .btn {
    width: 100%;
    background: #3578E4;
    border-color: #3578E4;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
    padding: 15px 20px;
}

.accessfacebook-content .btn:hover {
    background: #2566cf;
    border-color: #2566cf;
}

.accessfacebook-content ul li {
    font-size: 14px;
    line-height: 18px;
}

.create-template-form .c-select.basic-multi-select .css-13cymwt-control, 
.create-template-form .c-select.basic-multi-select .css-t3ipsp-control, 
.contact-m-form .c-select.basic-multi-select .css-13cymwt-control, 
.contact-m-form .c-select.basic-multi-select .css-t3ipsp-control {
    height: auto;
    padding: 5px 10px
}

.create-template-form .c-select.basic-multi-select .css-13cymwt-control .css-3w2yfm-ValueContainer, 
.create-template-form .c-select.basic-multi-select .css-t3ipsp-control .css-3w2yfm-ValueContainer, 
.contact-m-form .c-select.basic-multi-select .css-13cymwt-control .css-3w2yfm-ValueContainer, 
.contact-m-form .c-select.basic-multi-select .css-t3ipsp-control .css-3w2yfm-ValueContainer, 
.contact-m-form .c-select.basic-multi-select .css-13cymwt-control .css-1dyz3mf,
.css-1dyz3mf {
    padding: 2px 0px !important;
}

.css-1p3m7a8-multiValue {
    margin-left: 0 !important;
    margin-right: 4px !important;
}

.select__control .select__indicator svg {
    display: none;
}

.select__control .select__indicator {
    width: 10px;
    height: 5px;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 5px solid #4B4A4A;
    position: relative;
    top: 3px;
}

.select__control--menu-is-open .select__indicator {
    transform: rotate(180deg) !important;
}



/* NEW STYLE DATE 26 JUNE 2024 */

.templates-body-empty .container {
    /* width: 90%;
    max-width: 850px; */
    background-color: #fff;
    border-radius: 5px;
    padding-top: 100px;
    padding-bottom: 100px;
    margin: 25px auto;
}

.templates-body-empty .tb-empty-content img {
    width: 44px;
    height: auto;
    margin-bottom: 10px;
}

.templates-body-empty .tb-empty-content .tb-page-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #000;

}

.templates-body-empty .tb-empty-content {
    color: #4B4A4A;
    margin-bottom: 25px;
}

.template-recordtable {
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 100px;
}

.preview-sec.preview-sec-new {
    min-height: auto;
    padding: 20px 30px;
    padding-top: 10px;
}

.preview-sec.preview-sec-new .prev-sec-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.prev-sec-header .close-preview {
    border: none;
    background-color: transparent;
    padding: 2px;
}

.tost-msg {
    border: 1px solid;
    border-radius: 6px;
    position: fixed;
    top: 80px;
    right: 60px;
    padding: 12px 12px 16px 12px;
    background-color: #fff;
    width: 330px;
    transition: 0.3s all;
    -webkit-transform: translateX(150%);
    transform: translateX(150%);
    opacity: 0;
}

.tost-msg.show {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

.tost-msg.success {
    border-color: #6FCF97;
    box-shadow: 0px 2px 26px 0px #52D7001A;
}

.tost-msg .tost-body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #4B4A4A;
}

.tost-msg .tost-body .msg-title {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-bottom: 4px;
}

.tost-msg .hide-tost {
    position: absolute;
    top: 10px;
    right: 12px;
    border: 0;
    background-color: transparent;
    width: 20px;
    height: 20px;
    padding: 0;
    text-align: center;
    box-shadow: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Mobile Navbar SIDE BAR */

.navbar-mob-open.btn {
    background-color: transparent;
    border: 0;
    padding: 2px 4px;
}

.navbar-mob-open.btn:hover, 
.navbar-mob-open.btn:active,
.navbar-mob-open.btn:focus {
    box-shadow: none;
    background-color: transparent;
    outline: none;
    border: none;
}

.mobile-navbar {
    background-color: #165E5B;
}

.mobile-navbar .offcanvas-header {
    justify-content: flex-end;
}

.mobile-navbar .offcanvas-header .btn-close {
    background-color: #d3d3d3;
    opacity: 1;
    border-radius: 2px;
    background-size: 13px;
}

.mobile-navbar .mobile-nav.inner {
    width: 100%;
    height: 100%;
    background-color: #165E5B;
    padding: 0;
    padding-left: 20px;
}

.mobile-navbar .mobile-nav.inner ul li a {
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(221 221 221 / 9%);
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    text-decoration: none;
}

.mobile-navbar .mobile-nav.inner ul li .btn-dropdown {
    width: 100%;
    border: 0;
    padding-left: 0;
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
}

.mobile-navbar .mobile-nav.inner ul li .btn-dropdown::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 7px;
    height: 7x;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-top: 7px solid #fff;
}

.dropdown-content {
    display: none;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    max-height: 0;
}

.dropdown-content.show {
    display: block;
    max-height: 400px;
}

.dropdown-content ul {
    padding-left: 20px;
}

@media (max-width: 1200px) {

    .preview-sec {
        padding: 15px 20px;
    }

    .preview-sec.preview-sec-new .event-time .e-time {
        padding: 8px;
        font-size: 12px;
    }

    .preview-sec.preview-sec-new {
        padding: 10px 15px;
    }

    .preview-sec.preview-sec-new .preview-screen {
        padding: 10px 15px;
    }

    .preview-sec .preview-body .link-footer a {
        font-size: 12px;
        padding: 15px 5px;
    }

    .preview-sec .preview-body .link-footer a img {
        margin-right: 3px;
    }
}


/* NEW STYLE DATE 26 JUNE 2024 END */


@media (max-width: 992px) {
    .preview-sec {
        padding: 10px 20px;
        min-height: auto;
    }

    .create-template-form .button-details .button-text {
        flex-wrap: wrap;
    }

    .call-to-action-details .form-control, .call-to-action-details .c-select {
        max-width: 100% !important;
        margin-bottom: 15px !important;
    }

    .template-lsit {
        padding: 10px;
    }

    .accessfacebook-content {
        padding: 30px 30px;
    }

    .template-heading {
        font-size: 20px;
    }

    .template-header .btn-main.btn-main-mob {
        padding: 3px 5px;
        font-size: 30px;
        width: 45px !important;
        height: 40px;
    }

    .template-header .btn-main.btn-main-mob span {
        display: none;
    }

    .template-header .btn-main.btn-main-mob svg {
        width: 25px !important;
        height: 25px !important;
    }
}

@media (max-width: 767px) {
    .navbar-end-btn {
        font-size: 14px;
    }

    .accessfacebook-content {
        padding: 30px 20px;
    }

    .accessfacebook-content h2 {
        font-size: 20px;
        line-height: 24px;
    }

    .accessfacebook-content .access-img img {
        max-width: 180px;
    }

    .accessfacebook-content .highlighted-pra {
        padding: 10px 15px;
        margin-bottom: 20px;
    }

    .template-nav ul li {
        font-size: 14px;
        font-weight: 500;
    }

    .preview-sec .mob-heading {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #000;
    }

    .preview-sec .preview-body .link-footer a {
        font-size: 14px;
    }

    .template-heading {
        font-size: 18px;
    }

    .whatsapp-modal .modal-footer {
        justify-content: flex-start;
        padding-top: 0;
    }

    .whatsapp-modal .modal-footer .btn {
        padding: 8px 20px;
        font-size: 14px;
        flex: 1 1 auto;
    } 

    .whatsapp-modal.confirm-modal .modal-footer {
        justify-content: flex-end;
    }

    .whatsapp-modal.confirm-modal .modal-footer .btn {
        flex: none;
    }

    .modal-csv .Upload-cvs-file {
        padding: 10px;
    }

    .modal-csv .Upload-cvs-file .file-body {
        font-size: 13px;
    }

    .tost-msg {
        right: 15px;
        width: 100%;
        max-width: 330px;
    }

    .templates-body-empty .container {
        max-width: 95%;
    }

    .whatsapp-modal .modal-body {
        padding: 10px 0px;
    }

    .preview-sec .preview-screen .content {
        max-height: calc(100vh - 65vh);
        line-height: 22px;
    }
}

@media (min-width: 576px) {
    .whatsapp-modal .modal-dialog {
        max-width: 560px;
    }

    .whatsapp-modal.select_tempalte-modal .modal-dialog {
        max-width:  730px;
    }
}

@media (max-width: 500px) {
    .template-nav ul li {
       padding: 15px 10px;
       font-size: 12px;
    }

    .template-heading {
        font-size: 16px;
    }
}

@media (max-width: 400px) {
    .form-container .form-header ul li .profile-img {
        margin-bottom: 20px;
    }

    .template-lsit .container .single-template {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .template-lsit .container .single-template .content-info {
        margin-bottom: 20px;
    }

    .template-nav ul li {
        padding: 10px 5px;
        font-size: 11px;
     }
}

@media (max-width: 350px) {
    .preview-sec .preview-body .link-footer a {
        font-size: 12px;
    }
}



