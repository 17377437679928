

.input-search input {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: var(--default-radius);
    height: 40px;
    line-height: 40px;
    color: rgba(73, 73, 73, 0.7);
    padding: 10px 18px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    width: 100%;
    max-width: 310px;
}

.input-search input:focus, .input-search input:active, .input-search input:hover {
    outline: none;
    box-shadow: none;
}

@media (max-width: 576px) {
    .input-search input:focus, .input-search input {
        max-width: 100%;
    }
}