

.widget-form .widget-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 15px;
}

.widget-form .widget-pra {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 30px;
}

.widget-form .c-select {
    max-width: 100% !important;
}

.color-picker-field {
    background-color: #F3F7F7;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 14px;
    color: #494949;
    margin-bottom: 20px;
    border: none;
    width: 100%;
}

input[type=color]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
    padding: 0;
}
  
input[type=color]::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 50%;
    padding: 0;
}

.button-close {
    margin-bottom: 20px;
}

.button-close:hover, 
.button-close:focus {
    border: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;
}

.btn-simple {
    background-color: transparent;
    border: 1px solid transparent;
    padding: 6px 10px;
    color: #165E5B;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 20px;
}

.btn-simple i {
    font-size: 22px;
    position: relative;
    top: 2px;
}

.btn-simple:hover, 
.btn-simple:focus {
    border: 1px solid #165E5B;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    color: #165E5B;
}

.code-tabs .react-tabs__tab-list {
    border: 0.5px solid #165E5B;
    padding: 3px;
    border-radius: 5px;
    width: fit-content;
    position: relative;
}

.code-tabs .react-tabs__tab-list .react-tabs__tab {
    width: 110px;
    background-color: transparent;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border: none;
    text-align: center;
    transition: color 0.15s ease-in;
    z-index: 2;
    bottom: 0;
}

.code-tabs .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
    background-color: #165E5B;
    color: #fff;
}

.code-tabs .react-tabs__tab-list .react-tabs__tab:focus::after {
    display: none;
}

.code-tabs .react-tabs__tab-panel {
    background-color: #F5F5F5;
    border-radius: 4px;
    padding: 30px 20px 20px 20px;
    margin-top: 15px;
}

.code-tabs .react-tabs__tab-list .tab-bg {
    position: absolute;
    top: 50%;
    left: 3px;
    width: 110px;
    border-radius: 3px;
    background-color: #165E5B;
    height: 90%;
    z-index: 1;
    transition: 0.25s ease-out;
    transform: translateY(-50%);
}

@media (max-width: 767px) {
    .code-tabs .react-tabs__tab-list {
        width: 100%;
        display: flex;
    }

    .code-tabs .react-tabs__tab-list .react-tabs__tab {
        width: 100%;
        flex: 1 1 auto;
    }
}

