

.integration-card {
    box-shadow: 0px 3px 5px 0px #00000014;
    border-radius: 5px;
    margin: 10px 0;
    padding: 20px 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.integration-card .integration-card-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 27px;
}

.integration-card .integration-card-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: rgb(75, 74, 74);
    margin-bottom: 10px;
    max-width: 320px;
}

.integration-card .card-link a {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E9F2FF;
    text-decoration: none;
}

.integration-card .copy-link {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 5px;
    padding: 6px 12px;
    background-color: #E9F2FF;
    width: 110px;
    height: auto;
    cursor: pointer;
}

.integration-card .copy-link ul li {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: #919191;
}

.integration-card .copy-link:hover ul li {
    color: #000;
}

.integration-card .copy-link:hover ul li svg path {
    fill: #000000 !important;
}