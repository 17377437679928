

.modal-table {
    height: 100%;
    max-height: 270px;
    overflow: hidden;
    overflow-y: auto;
}

.modal-table::-webkit-scrollbar {
    width: 7px;
}
  
  /* Track */
  .modal-table::-webkit-scrollbar-track {
    background: #e7e6e6;
}
  
  /* Handle */
.modal-table::-webkit-scrollbar-thumb {
    background: #535353;
    border-radius: 25px;
}

.modal-table tr th {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding: 12px 16px;
    color: #000;
}

.modal-table tr td {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    padding: 12px 16px;
    color: #000;
}