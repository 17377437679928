
.chat-section {
    overflow: hidden;
    width: 100%;
    position: relative;
    height: calc(100vh - 75px);
}

.chat-section .row .col-xxl-3, 
.chat-section .row .col-xxl-6 {
    padding-left: 0;
    padding-right: 0;
}

.chat-section .user-list-header {
    padding: 15px 11px 5px 11px;
    /* border-bottom: 0.5px solid var(--default-border-color); */
    background-color: var(--bg-white);
    /* height: 85px; */
    position: relative;
}

.chat-section .user-list-header .btn-main {
    width: 100%;
    max-width: 31px;
    height: 31px;
    padding: 4px;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: var(--bg-primary);
    color: #fff;
}

.chat-section .user-list-header .btn-light {
    background-color: var(--bg-white);
    border-color: #E0E0E0;
    width: 100%;
    padding: 0;
    height: 100%;
    color: #403E3E;
}

.chat-section .user-list-header .search {
    float: left;
    background-color: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    padding: 5px 15px;
    width: 100%;
    transition: 0.3s all;
    font-size: 14px;
    color: #494949;
    /* cursor: pointer; */
    height: 31px;
}

.chat-section .user-list-header .search.active {
    width: 100%;
    cursor: initial;
}

.chat-section .user-list-header .search:focus, 
.chat-section .user-list-header .search:active {
    outline: none;
    box-shadow: none;
    border-color: var(--bg-primary);
}

.chat-section .user-list-header .search-box input:focus, 
.chat-section .user-list-header .search-box input:active {
    outline: none;
    box-shadow: none;
}

.chat-section .user-list .chat-tab .btn-main {
    background-color:#fff;
    border: 1px solid #D9D9D9;
    color: #4B4A4A;
    width: 100%;
    padding: 6px 20px;
    font-size: 14px;
}

.chat-section .user-list .chat-tab .btn-main:hover, 
.chat-section .user-list .chat-tab .btn-main.active {
    background-color: var(--bg-primary);
    border-color: var(--bg-primary);
    color: #fff;
}

.chat-section .active-user-list {
    padding: 0px 0px 40px 0px;
    overflow-y: auto;
    height: calc(100vh - 160px);
    position: relative;
    background-color: #fff;
}

.chat-section .active-user-list::-webkit-scrollbar {
    width: 5px;
}

.chat-section .active-user-list::-webkit-scrollbar-thumb {
    background: #888;
}

.chat-section .active-user-list .single-user {
    background-color: var(--bg-white);
    border-radius: 0;
    padding: 11px 11px 11px 18px;
    position: relative;
    border-bottom: 0.5px solid #D9D9D9;
    cursor: pointer;
}

.chat-section .active-user-list .single-user:last-child {
    border-bottom: 0;
}

.chat-section .active-user-list .single-user:hover {
    background-color: #E9F2FF;
}

.chat-section .active-user-list .single-user.active {
    background-color: #E9F2FF;
}

.chat-section .active-user-list .single-user .user-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #BCF1D5;
    position: relative;
    background-image: url('../../images/no-user-photo.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: inherit;
}

.chat-section .active-user-list .single-user .user-image .online-status {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #5FD595;
    border: 1px solid #FFFFFF;
    bottom: 3px;
    right: 2px;
}

.chat-section .active-user-list .single-user .user-image img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.chat-section .active-user-list .single-user .time-sec {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000;
}

.chat-section .active-user-list .single-user .chat-msg-badge {
    position: absolute;
    top: 0;
    right: 0;
    height: 23px;
    width: 23px;
    line-height: 23px;
    align-items: center;
    justify-content: center;
    background-color: #BCF1D5;
    font-size: 11px;
    font-weight: 600;
    color: #000;
    border-radius: 50%;
    display: none;
}

.chat-section .active-user-list .single-user.unread-chat .chat-msg-badge {
    display: flex;
}

.chat-section .active-user-list .single-user .user-details {
    position: relative;
    display: flex;
    /* align-items: center; */
    flex-wrap: nowrap;
    /* padding-right: 55px; */
}

.chat-section .active-user-list .single-user .user-details .user-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--default-text-color);
}

.chat-section .active-user-list .single-user .user-details .msg-title {
    font-weight: 300;
    font-size: 12px;
    line-height: 19px;
    width: 100%;
    max-width: 230px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--light-text);
}

.chat-section .active-user-list .single-user .user-details .chat-tag {
    font-weight: 600;
    font-size: 11px;
    color: #000;
    padding: 2px 6px;
    border-radius: 5px;
    text-align: center;
}

.chat-section .active-user-list .single-user .user-details .chat-tag.marketing {
    background-color: #FFE3D4;
}

.chat-section .active-user-list .single-user .user-details .chat-tag.queries {
    background-color: #FFF2CD;
}

.chat-section .active-user-list .single-user .user-details .chat-tag.support {
    background-color: #E8F1FF;
}

.chat-section .active-user-list .single-user .user-details .chat-tag.sales {
    background-color: #FBE2FF;
}

.chat-section .active-user-list .single-user.no-user .user-image img {
    display: none;
}

.chat-section .live-chat-sec {
    background-color: var(--bg-white);
    border-left: 0.5px solid var(--default-border-color);
    border-right: 0.5px solid var(--default-border-color);
}

.chat-section .live-chat-sec .chat-header {
    padding: 20px 15px;
    position: relative;
    border-bottom: 1px solid var(--default-border-color);
    position: relative;
    height: 85px;
    display: flex;
    justify-content: space-between;
}

.chat-section .live-chat-sec .chat-header ul.user-info {
    align-items: center;
}

.chat-section .live-chat-sec .chat-header ul.user-info li {
    margin-right: 8px;
}

.chat-section .live-chat-sec .chat-header ul.user-info li .back-btn {
    color: var(--bg-primary);
    font-size: 25px;
}

.chat-section .live-chat-sec .chat-header ul.user-info li .user-image {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: relative;
    background-color: var(--light-text);
    position: relative;
}

.chat-section .live-chat-sec .chat-header ul.user-info li .user-image img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.chat-section .live-chat-sec .chat-header ul.user-info li .user-image .user-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: #5FD595;
    position: absolute;
    bottom: 2px;
    right: 1px;
}

.chat-section .live-chat-sec .chat-header ul.user-info li .user-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
}

.chat-section .live-chat-sec .chat-header .chat-option {
    position: relative;
    padding: 0px 5px;
}

.chat-section .live-chat-sec .chat-header .chat-option .chat-o-btn {
    width: 38px;
    height: 38px;
    background-color: var(--body-bg);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #403E3E;
    border: 1px solid transparent;
    font-size: 20px;
    line-height: inherit;
    padding: 0;
}

.chat-section .live-chat-sec .chat-header .chat-option .chat-o-btn:hover, 
.chat-section .live-chat-sec .chat-header .chat-option .chat-o-btn:focus, 
.chat-section .live-chat-sec .chat-header .chat-option .chat-o-btn:active {
    border: 1px solid transparent;
    box-shadow: none;
    background-color: var(--bg-primary);
    color: #fff;
}

.chat-section .live-chat-sec .chat-header .chat-option .chat-o-btn::after {
    display: none;
}

.chat-section .live-chat-sec .chat-header .chat-option .chat-o-btn img {
    width: auto;
}

.chat-section .live-chat-sec .chat-header .chat-option .option-dropdown, 
.msg-row .chat-option .option-dropdown {
    top: 110% !important;
    left: unset !important;
    right: 0 !important;
    background-color: var(--bg-white);
    border-radius: var(--default-radius);
    width: 160px;
    height: auto;
    border: 0.2px solid rgba(73, 73, 73, 0.5);
    z-index: 99;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
    padding: 0;
    overflow: hidden;
}

@-moz-keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
  @-webkit-keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
  @-o-keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }

.chat-section .live-chat-sec .chat-header .chat-option .option-dropdown a, 
.msg-row .chat-option .option-dropdown a {
    padding: 11px 14px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: rgba(73, 73, 73, 0.7);
    border-bottom: 0.5px solid #D9D9D9;
    cursor: pointer;
}

.chat-section .live-chat-sec .chat-header .chat-option .option-dropdown a:last-child, 
.msg-row .chat-option .option-dropdown a:last-child {
    border-bottom: none;
}

.chat-section .live-chat-sec .chat-header .chat-option .option-dropdown a:focus, 
.chat-section .live-chat-sec .chat-header .chat-option .option-dropdown a:active, 
.msg-row .chat-option .option-dropdown a:focus,
.msg-row .chat-option .option-dropdown a:active {
    background-color: transparent;
}

.chat-section .live-chat-sec .chat-header .assign-to-user li {
    font-weight: 400;
    color: #000;
    line-height: 19px;
    font-size: 14px;
    padding: 0px 3px;
    margin-right: 0 !important;
}

.chat-section .live-chat-sec .chat-header .assign-to-user li .user-icon-badge {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: var(--bg-primary);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-transform: uppercase;
}

.chat-section .live-chat-sec .chat-header .assign-to-user li .user-name {
    font-weight: 500;
}

.chat-section .live-chat-sec .chat-header .assign-to-user li .user-tag {
    width: auto;
    border-radius: 5px;
    padding: 3px 6px;
    font-weight: 600;
    color: #000;
    font-size: 11px;
    cursor: pointer;
}

.chat-section .live-chat-sec .chat-header .assign-to-user li .user-tag.marketing {
    background-color: #FFE3D4;
}

.chat-section .live-chat-sec .chat-header .assign-to-user li .user-tag.sale {
    background-color: #FBE2FF;
}

.chat-section .live-chat-sec .chat-header .assign-to-user li .queries {
    background-color: rgb(232, 241, 255);
}

.chat-section .live-chat-sec .chat-header .assign-to-user li .dropdown-icon-side {
    cursor: pointer;
}

/* .chat-section .live-chat-sec .chat-header .chat-option:hover .option-dropdown {
    display: block;
} */

.chat-message-sec {
    height: calc(100vh - 160px) !important;
    position: relative;
    background-color: var(--bg-white);
    padding: 25px 15px 100px 15px;
    margin-bottom: 10px;
    width: 100%;
    overflow-y: scroll;
    /* transform: rotate(180deg);
    direction: rtl; */
}

/* .chat-message-sec .message-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 25px 25px 25px 15px;
    position: relative;
    display: table-cell;
    vertical-align: bottom;
} */

.chat-message-sec::-webkit-scrollbar {
    width: 5px;
}

.chat-message-sec::-webkit-scrollbar-thumb {
    background: #888;
}

.chat-message-sec .msg-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 15px;
    position: relative;
}

.chat-message-sec .msg-row.received {
    padding-left: 30px;
}

.chat-message-sec .msg-row.received .msg-body {
    width: 100%;
    max-width: 420px;
}

.chat-message-sec .msg-row.send-msg {
    padding-right: 35px;
    align-items: flex-end;
}

.chat-message-sec .msg-row.send-msg .msg-body {
    width: 100%;
    max-width: 420px;
}

.chat-message-sec .msg-row .user-img-c {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: var(--bg-primary);
    color: #fff;
    position: absolute;
    top: 20px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-message-sec .msg-row .user-img-c img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
}

.chat-message-sec .msg-row .user-img-c.no-img {
    font-weight: 500;
    font-size: 14px;
}

.chat-message-sec .msg-row .chat-user-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 6px;
}

.chat-message-sec .msg-row.send-msg .chat-user-name {
    text-align: right;
}

.chat-message-sec .msg-row.send-msg .user-img-c {
    right: 0;
    left: auto;
}

.chat-message-sec .msg-row .mesg-sec {
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000;
    position: relative;
    margin: 0;
    margin-bottom: 15px;
    width: fit-content;
    max-width: 417px;
    word-break: break-all;
    border-radius: var(--default-radius);
    position: relative;
    /* transform: rotate(180deg);
    direction: ltr; */
}

.chat-message-sec .msg-row .mesg-sec img {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 6px;
    border-radius: var(--default-radius);
}

.chat-message-sec .msg-row .mesg-sec.received {
    background-color: var(--received-bg);
}

.chat-message-sec .msg-row.send {
    align-items: flex-end;
}

.chat-message-sec .msg-row .mesg-sec.send {
    background-color: var(--send-bg);
    margin-left: auto;
}

.chat-message-sec .msg-row .mesg-sec.doc_msg ul li .file-content .file-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #000;
    margin-bottom: 3px;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-message-sec .msg-row .mesg-sec.doc_msg ul li .file-content .file-size {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #4B4A4A;
}

.chat-message-sec .msg-row .mesg-sec.doc_msg ul li img.doc_file-icon {
    display: none;
}

.chat-message-sec .msg-row .mesg-sec.doc_msg.doc ul li img.doc-icon {
    display: block;
}

.chat-message-sec .msg-row .mesg-sec.doc_msg.ppt ul li img.ppt-icon {
    display: block;
}

.chat-message-sec .msg-row .mesg-sec.doc_msg.pdf ul li img.pdf-icon {
    display: block;
}

.chat-message-sec .msg-row .mesg-sec.doc_msg.xls ul li img.xls-icon {
    display: block;
}

.chat-message-sec .msg-row .mesg-sec.doc_msg.doc_with_msg {
    padding: 0;
}


.chat-message-sec .msg-row .mesg-sec.doc_msg.doc_with_msg ul.nav {
    padding: 10px;
}

.chat-message-sec .msg-row .mesg-sec.doc_msg.doc_with_msg .doc_msg_text {
    border-top: 1px solid #7D7D7D33;
    padding: 14px;
}

.chat-message-sec .msg-row .mesg-sec.share-contact {
    padding: 0
}

.chat-message-sec .msg-row .mesg-sec.share-contact ul.nav {
    padding: 10px;
    gap: 10px;
    min-width: 255px;
}

.chat-message-sec .msg-row .mesg-sec.share-contact ul.nav li .user-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.chat-message-sec .msg-row .mesg-sec.share-contact ul.nav li .user-img img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.chat-message-sec .msg-row .mesg-sec.share-contact ul.nav li .share-user-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    color: #000;
    margin-top: 5px;
}

.chat-message-sec .msg-row .mesg-sec.share-contact .share-action ul {
    padding: 0;
    border-top: 1px solid #7D7D7D33;
}

.chat-message-sec .msg-row .mesg-sec.share-contact .share-action ul li .btn-share-contact {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    outline: none;
    border-radius: 0;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    border-right: 1px solid #7D7D7D33;
    padding: 12px 10px;
    width: 100%;
}

.chat-message-sec .msg-row .mesg-sec.share-contact .share-action ul li:last-child .btn-share-contact {
    border-right: 0;
}

.chat-message-sec .msg-row .time-area {
    width: 100%;
    max-width: 417px;
    text-align: right;
    padding: 0px 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 3px;
}

.write-msg-sec {
    background-color: #E4EFFF;
    padding: 20px 15px;
    position: absolute;
    height: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 99;
}

.write-msg-sec .text-input {
    margin-left: 53px;
    margin-right: 110px;
}

.write-msg-sec .text-input input {
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    font-weight: 300;
    font-size: 15px;
    height: 46px;
    line-height: 46px;
    color: rgba(73, 73, 73, 0.7);
    border: 0;
}

.write-msg-sec .command-sec {
    position: absolute;
    right: 0;
    padding: 10px 0px;
    width: 120px;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.write-msg-sec .command-sec ul li {
    margin-left: 5px;
    margin-right: 5px;
}

.write-msg-sec .command-sec ul li button {
    background-color: transparent;
    border: none;
    text-align: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.write-msg-sec .command-sec ul li button.btn-send {
    background-color: var(--bg-primary);
}

.write-msg-sec .command-sec ul li button.btn-mic {
    padding: 0;
}

.user-info-data .user-info-header {
    padding: 20px 36px;
    border-bottom: 0.5px solid var(--default-border-color);
    height: 85px;
}

.user-info-data .user-info-header .user-name, 
.user-info-data .info-row .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: var(--default-text-color);
    margin-bottom: 5px;
}

.user-info-data .user-info-header .online-status, 
.user-info-data .info-row .description {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: rgba(73, 73, 73, 0.7);
}

.user-info-data .user-info-header .user-name, 
.user-info-data .user-info-header .online-status {
    font-weight: 600;
}

.user-info-data .user-info-header .online-status span {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #5FD595;
    border: 1px solid #FFFFFF;
    display: inline-block;
}

.user-info-data .user-personal-info {
    padding: 17px 36px;
}

.user-info-data .info-row {
    margin-bottom: 14px;
}

.attached-file {
    float: left;
}

.attached-file .attached-btn {
    width: 42px;
    height: 42px;
    background: var(--bg-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    color: #fff;
}

.attached-file .attached-btn i {
    font-size: 25px;
    transform: rotate(38deg);
}

.attached-file .attached-btn:hover, 
.attached-file .attached-btn:active {
    background-color: rgba(22, 94, 91, 0.1) !important;
    color: #413f3f !important;
    border: none !important;
}

.attached-file .attached-btn::after {
    display: none;
}

.attached-file .attached-btn img {
    width: 20px;
    height: auto;
}

.attached-file .attached-option {
    border: none;
    padding: 0;
    margin-bottom: 10px;
    box-shadow: 0px 3px 13px 0px #0000001A;
    width: 185px;
    border-radius: 5px;
}

.attached-file .attached-option a {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #4B4A4A;
    padding: 12px 15px;
    border-bottom: 1px solid #7D7D7D33
}

.attached-file .attached-option a:last-child {
    border-bottom: 0;
}

.attached-file .attached-option a ul li {
    padding: 0px 5px;
}

.attached-file .attached-option a ul li:first-child {
    padding-left: 0;
}

.attached-file .attached-option a ul li .link-icon {
    /* width: 48px;
    height: 48px;
    border-radius: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #F4F4F4;
    border: 1px solid #D9EAE9; */
}

.attached-file .attached-option a ul li .link-icon i {
    font-size: 20px;
    color: #909090;
}

.attached-file .attached-option a:hover {
    background-color: transparent;
    color: var(--bg-primary);
}

/* .attached-file .attached-option a:hover .link-icon {
    background-color: var(--bg-primary);
}

.attached-file .attached-option a:hover .link-icon i {
    color: #fff;
} */

.modal-select-contact .modal-content {
    padding: 0 !important;
}

.modal-select-contact .modal-header {
    padding: 20px 30px 0px 30px !important;
}

.modal-select-contact .modal-body {
    padding: 0px 30px 0px 30px !important;
}

.modal-select-contact .modal-body .contact-col {
    padding-bottom: 20px;
    height: auto;
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
}

.modal-select-contact .modal-body .contact-col::-webkit-scrollbar {
    width: 5px;
}

.modal-select-contact .modal-body .contact-col::-webkit-scrollbar-thumb {
    background: #888;
}

.modal-select-contact .modal-body .contact-group {
    cursor: pointer;
    padding: 8px 0px;
    border-radius: 5px;
}

.contact-group:hover {
    background: #F0F2F5;
}

.modal-select-contact .modal-footer {
    padding: 20px 30px 20px 30px !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    background: #F4FBFA;
    justify-content: space-between;
}

.modal-select-contact .modal-footer .btn {
    padding: 12px 10px !important;
}

.modal-select-contact .modal-footer .attention-msg {
    color: var(--bg-primary);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.empty-state {
    background: #f0f2f5;
    height: calc(100vh - 72px) !important;
    text-align: center;
    padding-top: 75px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.empty-state img {
    width: 90%;
}

.empty-chat-sec {
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty-chat-sec h1 {
    font-size: 20px;
    line-height: 158.01%;
    font-weight: 500;
}

.empty-chat-sec p {
    font-size: 14px;
    font-weight: 400;
    color: #888888;
}

.chat-info-side {
    padding: 10px 12px;
}

.chat-info-side .accordion-item {
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #D9D9D9 !important;
    border-radius: 5px;
    /* overflow: hidden; */
}

.chat-info-side .accordion-item .accordion-header .accordion-button {
    font-weight: 500;
    color: #000;
    font-size: 14px;
    border-radius: 5px;
}

.chat-info-side .accordion-item .accordion-header .accordion-button:focus,
.chat-info-side .accordion-item .accordion-header .accordion-button:active {
    box-shadow: none;
}

.chat-info-side .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    background-color: #fff;
    border-bottom: 0.5px solid #D9D9D9;
    box-shadow: none;
}

.chat-info-side .accordion-item .accordion-header .accordion-button::after {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #000;
}

.chat-info-side .accordion-item .accordion-body {
    padding: 16px 22px;
}

.chat-info-side .accordion-item .accordion-body .user-info-data .user-personal-info, 
.tab-pane .user-info-data .user-personal-info {
    padding: 0;
    margin-bottom: 16px;
}

.chat-info-side .accordion-item .accordion-body .user-info-data .user-personal-info .info-row .title, 
.tab-pane .user-info-data .user-personal-info .info-row .title {
    font-size: 12px;
    font-weight: 500;
    color: #898989;
    margin-bottom: 0;
}

.chat-info-side .accordion-item .accordion-body .user-info-data .user-personal-info .info-row .description, 
.tab-pane .user-info-data .user-personal-info .info-row .description {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.chat-info-side .accordion-item .accordion-body .css-b62m3t-container .css-13cymwt-control, 
.chat-info-side .accordion-item .accordion-body .css-b62m3t-container .css-t3ipsp-control, 
.tab-pane .css-b62m3t-container .css-13cymwt-control, 
.tab-pane .css-b62m3t-container .css-t3ipsp-control {
    background-color: #fff;
    border: 1px solid #D9D9D9;
    border-color:#D9D9D9 !important;
}

.chat-info-side .accordion-item .accordion-body .css-13cymwt-control .css-1p3m7a8-multiValue, 
.tab-pane .css-13cymwt-control .css-1p3m7a8-multiValue {
    background-color: #FFE3D4 !important;
    color: #000;
    font-weight: 600;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-header .btn-note, 
.tab-pane .note-body .note-header .btn-note {
    border: none;
    padding: 5px;
    padding-top: 0;
    background-color: transparent;
    color: var(--bg-primary);
    font-weight: 600;
    font-size: 14px;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-header .btn-note:hover, 
.tab-pane .note-body .note-header .btn-note:hover {
    color: #2d2d2d;
}

.chat-info-side .accordion-item .accordion-body .note-body, 
.tab-pane .note-body {
    height: calc(100vh - 325px);
    padding-bottom: 40px;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-body-inner, 
.tab-pane .note-body .note-body-inner {
    overflow-y: scroll;
    height: 100%;
    padding-right: 10px;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-body-inner::-webkit-scrollbar,
.tab-pane .note-body .note-body-inner::-webkit-scrollbar {
    width: 7px;
}
  
  /* Track */
.chat-info-side .accordion-item .accordion-body .note-body .note-body-inner::-webkit-scrollbar-track, 
.tab-pane .note-body .note-body-inner::-webkit-scrollbar-track {
    background: #e7e6e6;
}
  
  /* Handle */
.chat-info-side .accordion-item .accordion-body .note-body .note-body-inner::-webkit-scrollbar-thumb, 
.tab-pane .note-body .note-body-inner::-webkit-scrollbar-thumb {
    background: #535353;
    border-radius: 0;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-panel, 
.tab-pane .note-body .note-panel {
    background-color: #E9F2FF;
    border-radius: 5px;
    padding: 12px;
    position: relative;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 10px;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-panel .note-option, 
.tab-pane .note-body .note-panel .note-option {
    position: absolute;
    padding: 0px 0px;
    top: 12px;
    right: 12px;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-panel .note-option .chat-o-btn, 
.tab-pane .note-body .note-panel .note-option .chat-o-btn {
    width: 18px;
    height: 18px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #403E3E;
    border: 1px solid transparent;
    font-size: 20px;
    line-height: inherit;
    padding: 0;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-panel .note-option .chat-o-btn::after, 
.tab-pane .note-body .note-panel .note-option .chat-o-btn::after {
    display: none;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-panel .note-option .chat-o-btn:hover, 
.tab-pane .note-body .note-panel .note-option .chat-o-btn:hover {
    background-color: transparent;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-panel .note-option .option-dropdown, 
.tab-pane .note-body .note-panel .note-option .option-dropdown {
    top: 110% !important;
    left: unset !important;
    right: 0 !important;
    background-color: var(--bg-white);
    border-radius: var(--default-radius);
    width: 110px;
    height: auto;
    border: 0.2px solid rgba(73, 73, 73, 0.5);
    z-index: 99;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
    padding: 0;
    overflow: hidden;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-panel .note-option .option-dropdown a, 
.tab-pane .note-body .note-panel .note-option .option-dropdown a {
    padding: 8px 14px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #000;
    border-bottom: 0.5px solid #D9D9D9;
    cursor: pointer;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-panel .note-user, 
.tab-pane .note-body .note-panel .note-user {
    font-weight: 600;
    margin-bottom: 5px;
}

.chat-info-side .accordion-item .accordion-body .note-body .note-panel .note-date, 
.tab-pane .note-body .note-panel .note-date {
    font-size: 12px;
    margin-bottom: 10px;
    color: #4B4A4A;
}


.user-list-header-mob {
    padding: 18px 16px;
    box-shadow: 0px 3px 5px 0px #00000014;
    margin-bottom: 5px;
    background-color: #fff;
    color: #000;
}

.user-list-header-mob .chatbox-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-list-header-mob .btn-main {
    width: 100%;
    max-width: 46px;
    height: 37px;
    padding: 7px 10px;
    font-size: 20px;
}

.chat-header-tab ul li button.btn-navTab {
    border: 0;
    padding: 10px 3px;
    color: #B6B6B6;
    font-size: 14px;
    font-weight: 600;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.chat-header-tab ul li button.btn-navTab.active {
    color: #0994CA;
    border-bottom: 1px solid #0994CA;
}

.chat-header-tab ul li button.btn-navTab.active svg path {
    fill: #0994CA !important;
}

.tab-content-area-mob .tab-pane {
    display: none;
}

.tab-content-area-mob .tab-pane.active {
    display: block;
}

.chat-message-sec .msg-row.msg-option .mesg-sec {
    padding-right: 50px;
}

.msg-row .chat-option {
    position: absolute;
    top: 20px;
    right: 5px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 99;
}

.msg-row .chat-option .chat-o-btn {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.msg-row .chat-option .option-dropdown {
    box-shadow: 0px 3px 13px 0px #0000001A;
    border: none;
}

.msg-row .chat-option .chat-o-btn::after {
    display: none;
}

.msg-row .chat-option .option-dropdown a {
    border-bottom: 1px solid #7D7D7D33;
    color: #4B4A4A;
    font-weight: 500;
}

.msg-row .chat-option .option-dropdown a:hover {
    background-color: rgb(188 241 212 / 40%);
}

.msg-row .chat-option .show .chat-o-btn svg  {
    transition: 0.2s all;
}

.msg-row .chat-option .show.dropdown .chat-o-btn svg {
    transform: rotate(180deg);
}

.reply-row {
    display: none !important;
    position: relative;
}

.reply-row.show {
    display: flex !important;
}

.reply-input {
    position: relative;
    padding: 10px 18px;
    margin-bottom: 15px;
    background-color: #fff;
    box-shadow: 0px 3px 13px 0px #0000001A;
    border-radius: 5px;
    color: #000;
    font-size: 14px;
    line-height: 19px;
    overflow: hidden;
}

.reply-input::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 6px;
    background-color: #5FD495;
}

.reply-row .col-sm-12 {
    padding-left: 60px;
    padding-right: 115px;
}

.reply-input .ri-header {
    font-weight: 500;
    margin-bottom: 6px;
}

.reply-input .ri-msg {
    font-weight: 400;
}

.reply-row .close-reply {
    border: 0;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 5px;
    position: absolute;
    top: 35%;
    right: 6%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.msg-row.view-reply .mesg-sec {
    padding: 0 !important;
} 

.msg-row .mesg-sec.send .reply-msg-view {
    position: relative;
    padding: 16px 40px 16px 18px;
    background-color: #CEF8E1;
    overflow: hidden;
    border-radius: 4px;
}

.msg-row .mesg-sec.send .reply-msg-view::before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 6px;
    height: 100%;
    background-color: #5FD495;
}

.msg-row .mesg-sec.send .reply-ans{
    padding: 12px 16px;
}

.Edit_modal.modal-contact {
    padding: 0;
    border-radius: 5px;
}


.Edit_modal.modal-contact .modal-header {
    background-color: #165E5B;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    text-transform: capitalize;
    padding: 12px 25px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.Edit_modal.modal-contact .modal-header ul {
    gap: 12px;
}

.Edit_modal.modal-contact .modal-body .message-edit-content {
    position: relative;
    background-color: #fff;
    padding-bottom: 60px;
    height: 100%;
}

.Edit_modal.modal-contact .modal-body .message-edit-content .edit-content-area {
    padding: 10px 30px 30px 30px;
    width: 100%;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.Edit_modal.modal-contact .modal-body .message-edit-content .edit-content-area::-webkit-scrollbar {
    width: 10px;
}

.Edit_modal.modal-contact .modal-body .message-edit-content .edit-content-area:-webkit-scrollbar-track {
    background: #f1f1f1;
}

.Edit_modal.modal-contact .modal-body .message-edit-content .edit-content-area:-webkit-scrollbar-thumb {
    background: #888;
}

.Edit_modal.modal-contact .modal-body .message-edit-content .edit-content-area .edit-msg {
    width: fit-content;
}

.Edit_modal.modal-contact .modal-body .message-edit-content .edit-content-area .edit-msg .edit-msg-body {
    background-color: #BCF1D5;
    border-radius: 4px;
    padding: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.Edit_modal.modal-contact .modal-body .message-edit-content .edit-content-area .edit-msg .edit-msg-body .read-more button.rm {
    background-color: transparent;
    border: none;
    padding: 5px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
}

.Edit_modal.modal-contact .modal-body .message-edit-content .edit-content-area .edit-msg .edit-msg-time-status {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
    margin-top: 5px;
}

.Edit_modal.modal-contact .modal-body .message-edit-content .edit-content-area .edit-msg .edit-msg-time-status .chat-time {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.96px;
    color: #000;
}

.Edit_modal.modal-contact .modal-body .message-edit-content .msg-area {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 12px;
    background-color: #E4EFFF;
    display: flex;
    align-items: center;
    gap: 15px
}

.Edit_modal.modal-contact .modal-body .message-edit-content .msg-area input {
    background-color: #fff;
    padding: 15px 25px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #4B4A4A;
    height: 46px;
    box-shadow: none;
    border: none;
    outline: none;
    width: 100%;
}

.Edit_modal.modal-contact .modal-body .message-edit-content .msg-area .btn-send {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #165E5B;
    border: 0;
    box-shadow: none;
    outline: none;
    padding: 0;
}

/* ==== Inbox document and video design adding design into inbox ==== */

.doc-adding, 
.img-adding {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px 22px;
    background-color: #E4EFFF;
    z-index: 99;
    display: none;
    transition: .2s all;
}

.doc-adding.show, 
.img-adding.show {
    display: block;
}

.doc-adding .doc-header, 
.img-adding .img-header {
    text-align: right;
    margin-bottom: 13px;
}

.doc-adding .doc-header .close-doc, 
.doc-adding .doc-header .close-doc:active, 
.doc-adding .doc-header .close-doc:focus, 
.img-adding .img-header .close-doc, 
.img-adding .img-header .close-doc:active, 
.img-adding .img-header .close-doc:focus{
    border: 0;
    padding: 0;
    background-color: transparent;
}

.doc-adding .doc-body, 
.img-adding .img-body {
    width: 100%;
    max-width: 385px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 13px 0px #0000001A;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    gap: 30px;
    height: calc(100vh - 80%);
}

.doc-adding .doc-body .doc-img img {
    width: 35px;
    height: auto;
}

.doc-adding .doc-body .doc-description {
    line-height: 18px;
    font-weight: 400;
}

.doc-adding .doc-body .doc-description .doc-name {
    font-size: 18px;
    color: #000;
    margin-bottom: 12px;
}

.doc-adding .doc-body .doc-description .doc-size {
    font-size: 13px;
    color: #4B4A4A;
}

.doc-adding .doc-msg, 
.img-adding .img-msg {
    width: 100%;
    padding: 6px 0px;
    margin-bottom: 20px;
}

.doc-adding .doc-msg input.doc-details, 
.img-adding .img-msg input.img-msg-details {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 13px 24px;
    color: #494949B2;
    font-size: 14px;
    font-weight: 400;
    border: 0px;
    box-shadow: none;
    outline: none;
}

.doc-adding .doc-list, 
.img-adding .img-list {
    padding: 6px 70px;
    margin-bottom: 20px;
    position: relative;
}

.doc-adding .doc-list ul li .doc-item, 
.img-adding .img-list ul li .img-item {
    width: 62px;
    height: 62px;
    border-radius: 4px;
    border: 1px solid #7D7D7D80;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px;
    cursor: pointer;
    transition: .1s all;
    overflow: hidden;
    position: relative;
}

.doc-adding .doc-list ul li .doc-item:hover,
.doc-adding .doc-list ul li .doc-item.active, 
.img-adding .img-list ul li .img-item:hover,
.img-adding .img-list ul li .img-item.active {
    border: 3px solid #165E5B;
}

.doc-adding .doc-list ul li .doc-item .doc-img-icon {
    width: 29px;
    height: auto;
}

.doc-adding .doc-list ul li .doc-item.upload-doc, 
.img-adding .img-list ul li .img-item.upload-img {
    position: relative;
}

.doc-adding .doc-list ul li .doc-item.upload-doc input, 
.img-adding .img-list ul li .img-item.upload-img input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.doc-adding .doc-list .btn-send, 
.img-adding .img-list .btn-send {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #165E5B;
    border-color: #165E5B;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}

.img-adding .img-body {
    padding: 0;
    max-width: 440px;
}

.img-adding .img-body .added-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.img-adding .img-list ul li .img-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-adding .img-list ul li .img-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #00000066;
    display: none;
}

.img-adding .img-list ul li .img-item .delete-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 2;
    display: none;
}

.img-adding .img-list ul li .img-item:hover::before {
    display: block;
}

.img-adding .img-list ul li .img-item:hover .delete-btn {
    display: block;
}

/*==== End style of docuemnt adding design ==== */

/* ==== Sending Template style start here ==== */

.whatsapp-modal.select_tempalte-modal .modal-dialog .modal-content {
    padding: 0;
}

.whatsapp-modal.select_tempalte-modal .modal-dialog .modal-content .modal-header {
    padding: 20px 25px 10px 25px;
}

.whatsapp-modal.select_tempalte-modal .modal-dialog .modal-content .modal-body {
    padding: 25px;
}

.select_template .form-group {
    border: 1px solid #D9D9D999;
    background-color: #F3F7F7;
    padding: 10px 20px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
}

.select_template .form-group.active {
    background-color: #bbf1d5;
}

.select_template .form-group label {
    width: 100%;
    cursor: pointer;
}

.select_template .form-group label .template-label .template-name {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 21.28px;
    margin-bottom: 5px;
}

.select_template .form-group label .template-label .template-leng {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #4B4A4A;
}

.select_template .form-group .form-check-input {
    width: 18px;
    height: 18px;
    border-color: #000;
}

.select_template .form-group .form-check-input:checked {
    background-color: transparent;
    background-image: url('../../images/check-bg-radio.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 8px;
}

.whatsapp-modal.select_tempalte-modal .modal-dialog .search-template {
    background-color: #fff !important;
    border: 1px solid #0000001A !important;
}

.whatsapp-modal.select_tempalte-modal .modal-dialog .message-preview-sec {
    padding: 10px 15px 20px 20px;
}

.whatsapp-modal.select_tempalte-modal .modal-dialog .message-preview-sec .message-prreview-inner {
    padding: 12px 12px 20px 12px;
}

.whatsapp-modal.select_tempalte-modal .modal-dialog .message-preview-sec .link-footer {
    text-align: center;
    border-top: 1px solid #E9F2FF;
    background-color: #fff;
}

.whatsapp-modal.select_tempalte-modal .modal-dialog .message-preview-sec .link-footer a {
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    color: #0FA6E0;
    margin-bottom: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
    padding: 15px 10px;
    border-right: 1px solid #E9F2FF;
    margin-bottom: 0;
}

.whatsapp-modal.select_tempalte-modal .modal-dialog .message-preview-sec .link-footer a img {
    margin-right: 7px;
    width: 14px;
    height: auto;
}

.whatsapp-modal.select_tempalte-modal .modal-dialog .modal-content .modal-footer {
    border-top: 1px solid #0000001A;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 13px;
}

.chat-message-sec .msg-row .mesg-sec .message-preview-sec {
    padding: 15px 20px 15px 20px;
}

.chat-message-sec .msg-row .mesg-sec .message-preview-sec .message-prreview-inner {
    padding: 12px 20px 18px 24px;
}


@media (max-width: 1200px) {

    .chat-section .user-list-header .search {
        background-position: center right 16px;
    }
}

@media (max-width: 1300px) {

    .chat-section .active-user-list .single-user .user-image,
    .chat-section .active-user-list .single-user .user-image img {
        width: 36px;
        height: 36px;
    }
}

@media (max-width: 1120px) and (min-width: 992px) {
    .chat-section .live-chat-sec .chat-header .assign-to-user li {
        padding: 3px 3px;
        font-size: 12px;
    }

    .chat-section .live-chat-sec .chat-header ul.user-info li .user-name {
        font-size: 13px;
    }

    .chat-section .live-chat-sec .chat-header .assign-to-user li .user-tag {
        font-size: 10px;
        padding: 2px 4px;
    }

    .chat-section .live-chat-sec .chat-header .chat-option .chat-o-btn {
        width: 30px;
        height: 30px;
        font-size: 16px;
    }
}

@media (max-width: 992px) {

    .chat-section .active-user-list {
        padding: 0px 0px 40px 0px;
    }

    .user-info-data .user-info-header {
        padding: 20px 20px;
    }

    .user-info-data .user-personal-info {
        padding: 17px 20px;
    }

    .user-info-data .user-info-header .user-name, .user-info-data .info-row .title {
        font-size: 14px;
        line-height: 18px;
    }

    .user-info-data .user-info-header .online-status, .user-info-data .info-row .description {
        font-size: 13px;
        line-height: 18px;
    }

    .chat-section .active-user-list .single-user {
        padding: 8px 10px;
    }

    .chat-section .user-list-header .btn-main {
        /* padding: 10px; */
        font-size: 14px;
    }

    .chat-section .active-user-list .single-user .user-image {
        margin-right: 10px;
    }

    .chat-section .active-user-list .single-user .user-details .user-name {
        font-size: 14px;
        line-height: 18px;
    }

    .chat-section .active-user-list .single-user .user-details .msg-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100px;
        font-size: 12px;
    }

    .chat-message-sec .msg-row .mesg-sec {
        font-weight: 500;
    }

    .chat-section .user-list-header .search {
        background-position: center right 10px;
    }

    .assign-to-dsk {
        display: none;
    }

    .chat-section .live-chat-sec .chat-header {
        flex-direction: column;
        padding: 10px 0px;
        height: auto;
    }

    .chat-section .live-chat-sec .chat-header .chat-header-sm-md {
        border-bottom: 0.5px solid #D9D9D9;
        padding: 0px 15px 10px 0px;
    }

    .chat-section .live-chat-sec .chat-header .chat-header-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px 0px 15px;
    }
    
    .chat-message-sec {
        height: calc(100vh - 180px) !important;
    }

    .chat-section .live-chat-sec .chat-header ul.user-info li .user-image, 
    .chat-section .live-chat-sec .chat-header ul.user-info li .user-image img {
        width: 35px;
        height: 35px;
    }

    .chat-section .user-list .chat-tab .btn-main {
        padding: 6px 6px;
    }

    .reply-row .col-sm-12 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .reply-row .close-reply {
        right: 25px;
        top: 20px;
    }

    .doc-adding .doc-msg , 
    .img-adding .img-msg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
    }

    .doc-adding .doc-msg .btn-send, 
    .img-adding .img-msg .btn-send {
        background-color: #165E5B;
        border-color: #165E5B;
        padding: 0;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .doc-adding .doc-msg .btn-send img, 
    .img-adding .img-msg .btn-send img {
        width: 15px;
        height: auto;
    }

    .doc-adding .doc-msg input.doc-details, 
    .img-adding .img-msg input.img-msg-details {
        max-width: 85%;
    }

    .doc-adding .doc-list ul li .doc-item, .img-adding .img-list ul li .img-item {
        width: 52px;
        height: 52px;
    }

    .doc-adding .doc-list, .img-adding .img-list {
        padding: 6px 10px;
    }
}

@media (max-width: 850px) and (min-width: 768px) {
    .chat-section .user-list .chat-tab .btn-main {
        font-size: 12px;
    }

    .chat-section .live-chat-sec .chat-header .assign-to-user li {
        font-size: 12px;
    }

    .chat-section .live-chat-sec .chat-header .assign-to-user li .user-tag {
        font-size: 10px;
        padding: 2px 4px;
    }
}

@media (max-width: 768px) and (min-width: 768px) {
    .chat-section .user-list-header .btn-main {
        width: 75%;
    }

    .chat-section .user-list-header .search {
        /* width: 15%; */
        padding: 10px 5px;
    }

    .chat-section .user-list-header .search.active {
        width: 88%;
    }
}

@media (max-width: 767px) {

    .chat-section .live-chat-sec .chat-header {
        box-shadow: 0px 3px 5px 0px #00000014;
        margin-bottom: 5px;
        border-bottom: 0;
        padding: 10px 0px 0px 0px;
    }

    .chat-section .active-user-list {
        height: calc(100vh - 265px);
    }

    .chat-section .live-chat-sec {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
       
    }

    .chat-message-sec {
        height: calc(100vh - 215px) !important;
    }

    .chat-section .user-list-header .search {
        background-position: center right 15px;
        padding: 10px 20px;
        height: 38px;
    }

    .chat-section .user-list .chat-tab .btn-main {
        padding: 10px 20px;
    }

    .tab-pane .user-info-data .user-personal-info .info-row {
        padding: 20px;
        border-bottom: 0.5px solid #D9D9D9;
        margin: 0;
    }

    .tab-pane .user-info-data .user-personal-info .info-row:last-child {
        border-bottom: 0px;
    }

    .tab-pane .user-info-data .user-personal-info .info-row .title {
        margin-bottom: 5px;
    }

    .tab-pane .note-body {
        padding: 12px 6px 40px 16px;
        height: calc(100vh - 250px);
    }

    .tab-pane .note-body .note-header {
        margin-right: 16px;
        border-bottom: 0.5px solid #D9D9D9
    }

    .reply-row .close-reply {
        top: 25px;
    }
}

@media (max-width: 580px) {
    .reply-row .close-reply {
        right: 25px;
        top: 20px;
    }
}