.listing-table tr th, 
.listing-table tr td {
    border-bottom: 0.5px solid #D9D9D980;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 13px 17px;
    color: #000;
    vertical-align: middle;
    white-space: nowrap;
}

.listing-table tr th {
    background-color: #165E5B;
    color: #fff;
    font-weight: 600;
}

.listing-table tr td .eventName {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    max-width: 165px;
    white-space: pre-line;
}

.listing-table tr td .eventCreator {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    max-width: 165px;
    white-space: pre-line;
}

.listing-table tr td span.status-badge {
    display: block;
    padding: 3px 12px;
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    width: fit-content;
}

.listing-table tr td span.status-badge.in-review {
    color: #2B6EDA;
    background-color: #E8F1FF;
}

.listing-table tr td span.status-badge.rejected {
    color: #C5160C;
    background-color: #FFDBD9;
}

.listing-table tr td span.status-badge.approved {
    color: #165E5B;
    background-color: #A3FBCC;
}