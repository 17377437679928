
.user-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.user-name.user-name-details {
    border-bottom: 0.5px solid #D9D9D9;
    padding: 0;
}

.row.user-row:last-child .user-name.user-name-details {
    border-bottom: 0;
}

.user-name label {
    width: 100%;
    cursor: pointer;
}

.user-name ul li:first-child {
    margin-right: 16px;
}

.user-name ul li .user-img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.user-name ul li .user-img img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.user-name ul li .u-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}

.user-name.user-name-details ul li .user-email {
    font-weight: 400;
    font-size: 12px;
    color: #494949;
}

.user-name.user-name-details ul li .user-tag {
    width: fit-content;
    border-radius: 5px;
    padding: 3px 6px;
    font-weight: 600;
    color: #000;
    font-size: 11px;
    cursor: pointer;
}

.user-name.user-name-details ul li .user-tag.marketing {
    background-color: #FFE3D4;
}

.user-name.user-name-details ul li .user-tag.sale {
    background-color: #FBE2FF;
}

.user-name.user-name-details ul li .user-tag.queries {
    background-color: rgb(232, 241, 255);
}

.user-name .form-check-input{
    width: 20px;
    height: 20px;
    border-radius: 50% !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    margin-right: 20px;
}

.user-name .form-check-input:checked {
    background-color: var(--bg-primary);
    border-color: var(--bg-primary);
}

.edit-group {
    text-decoration: underline;
    color: var(--bg-primary);
    cursor: pointer;
}

.modal-select-assignTo .modal-body .modal-body-inner {
    height: 100%;
    max-height: 370px;
    overflow: hidden;
    overflow-y: auto;
}

.modal-select-assignTo .modal-body .modal-body-inner::-webkit-scrollbar {
    width: 7px;
}
  
  /* Track */
.modal-select-assignTo .modal-body .modal-body-inner::-webkit-scrollbar-track {
    background: #e7e6e6;
}
  
  /* Handle */
.modal-select-assignTo .modal-body .modal-body-inner::-webkit-scrollbar-thumb {
    background: #535353;
    border-radius: 25px;
}