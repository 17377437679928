
.subcription-sec .card {
    background: #F4FBFA;
    border: 1px solid rgba(22, 94, 91, 0.2);
    border-radius: 5px;
    width: 100%;
    max-width: 272px;
    margin: auto;
    margin: 0 18px;
}

.subcription-sec .card .card-body {
    padding: 25px 30px;
}

.subcription-sec .card .card-body .price-header {
    border-bottom: 1px solid rgba(22, 94, 91, 0.2);
    padding-bottom: 25px;
}

.subcription-sec .card .card-body .price-header .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 10px;
}

.subcription-sec .card .card-body .price-header .price ul li {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding-right: 6px;
}

.subcription-sec .card .card-body .price-header .price ul li .amount {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}

.subcription-sec .card .card-body .price-body {
    border-bottom: 1px solid rgba(22, 94, 91, 0.2);
    padding-bottom: 25px;
    padding-top: 25px;
}

.subcription-sec .card .card-body .price-body .body-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 15px;
}

.subcription-sec .card .card-body .price-body ul li {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 12px;
    position: relative;
    padding-left: 20px;
}

.subcription-sec .card .card-body .price-body ul li::before {
    content:'';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background: url('../../images/icons/price-tick.svg') center no-repeat;
    background-size: 100%;
}

.subcription-sec .card .card-body .price-footer {
    padding-top: 25px;
}

.subcription-sec .card .card-body .price-footer .btn {
    margin-bottom: 10px;
    padding: 8px 25px;
}

.compare-plan-table {
    background: #F4FBFA;
    border: 1px solid rgba(22, 94, 91, 0.2);
    border-radius: 5px;
    padding: 30px 40px;
}

.compare-plan-table tr th {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    padding: 5px 30px 25px 30px;
    color: #000;
    border: 0;
    border-bottom: 1px solid rgba(22, 94, 91, 0.2);
    vertical-align: middle;
}


.compare-plan-table tr td {
    padding: 17px 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    border: 0;
    border-bottom: 1px solid rgba(22, 94, 91, 0.2);
    vertical-align: middle;
}

.compare-plan-table tr td i {
    color: var(--bg-primary);
    font-size: 20px;
}

.compare-plan-table tr th:first-child, 
.compare-plan-table tr td:first-child {
    width: 220px;
    padding-left: 0;
}

.compare-plan-table tr:last-child td {
    border-bottom: 0px;
    padding-bottom: 0;
}

@media (max-width: 767px) {
    .subcription-sec .subcription-card {
        flex-wrap: wrap !important;
    }

    .subcription-sec .subcription-card .card {
        margin-bottom: 20px;
    }
}