
.accordion-header button ul li {
    padding-right: 10px;
}

.accordion-header button ul li .c-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}

.accordion-header button ul li .user-email {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.accordion-body .ded-btn, 
.tag-mob .ded-btn {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 32px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    float: left;
}

.accordion-body .contact-list div {
    font-weight: 400;
    color: #4B4A4A;
    font-size: 14px;
    line-height: 19px;
}

.accordion-body .contact-list .ub-title {
    font-weight: 600;
    line-height: 19px;
}