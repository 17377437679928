

.template-listing-collapse {
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 100px;
}

.template-listing-collapse .tlc-header {
    background-color: #165E5B;
    color: #fff;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}

.template-listing-collapse .tlc-body {
    padding: 8px 0px;
    padding-bottom: 0;
}

.template-listing-collapse .tlc-body .accordion .accordion-item, 
.template-listing-collapse .tlc-body .accordion .accordion-item .accordion-header, 
.template-listing-collapse .tlc-body .accordion .accordion-item .accordion-header .accordion-btn {
    border: none;
    border-radius: 0;
}

.template-listing-collapse .tlc-body .accordion .accordion-item {
    border-bottom: 1px solid #b7b2b2c2;
}

.template-listing-collapse .tlc-body .accordion .accordion-item .accordion-header .accordion-button {
    border-bottom: 0;
    box-shadow: none;
    display: block;
    position: relative;
}

.template-listing-collapse .tlc-body .accordion .accordion-item .accordion-header .accordion-button::after {
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ECF1FE;
    background-image: url('../../../images/icons/collapse-bottom.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: inherit;
    position: absolute;
    top: 12px;
    right: 20px;
}

.template-listing-collapse .tlc-body .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    background-color: #fff;
    color: inherit;
    box-shadow: none;
}

.template-listing-collapse .tlc-body .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    background-color: #5ed495;
    background-image: url('../../../images/icons/collapse-up.svg');
}

.template-header-mob {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.template-header-mob .templete-name {
    font-size: 15px;
    font-weight: 600;
    line-height: 19px;
}

.template-header-mob span.status-badge {
    display: block;
    padding: 3px 12px;
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    width: fit-content;
}

.template-header-mob span.status-badge.in-review {
    color: #2B6EDA;
    background-color: #E8F1FF;
}

.template-header-mob span.status-badge.rejected {
    color: #C5160C;
    background-color: #FFDBD9;
}

.template-header-mob span.status-badge.approved {
    color: #165E5B;
    background-color: #A3FBCC;
}

.template-accordion-body {
    font-weight: 400;
}

.template-accordion-body .temp-sec-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
}