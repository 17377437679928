
.group-search .input-search {
    display: flex;
    align-items: center;
    justify-content: end;
}

.tb-empty-content.groups img.groups-icons {
    width: 58px;
    height: auto;
}

.imp-exp-btn .hidden-title {
    position: absolute;
    top: -50px;
    left: 50%;
    width: 120px;
    height: auto;
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.02em;
    box-shadow: 0px 4px 15.1px 0px #0000002E;
    background-color: #000;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    display: none;
}

.imp-exp-btn .hidden-title::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    width: 15px;
    height: 15px;
    background-color: transparent;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 8px solid #000;
}

.imp-exp-btn:hover .hidden-title {
    display: block;
}