

.broadcast-top .btn-main {
    background-color: #fff;
    border-color: #D9D9D9;
    color: #4B4A4A;
    padding: 8px 20px;
}

.broadcast-top .btn-main:hover, 
.broadcast-top .btn-main.active {
    background-color: #165E5B;
    border-color: #165E5B;
    color: #fff;
}

.broadcast-card {
    position: relative;
    border-radius: 10px;
    border: 1px solid #7D7D7D4D;
    z-index: 1;
    overflow: hidden;
}

/* .broadcast-card::before {
    content:'';
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: -1;
} */

.broadcast-card .card-inner {
    width: 100%;
    padding: 30px 26px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.broadcast-card .broadcast-header {
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    color: #7D7D7D;
    text-transform: uppercase;
}

.broadcast-card .broadcast-header .total-number {
    font-size: 24px;
    font-weight: 700;
    line-height: 31.92px;
    color: #4B4A4A;
}

/* .broadcast-card .broadcast-header span {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 12px;
} */

/* .broadcast-card .broadcast-body {
    padding: 17px 0px 30px 0px;
} */

.broadcast-card .card-icon {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    text-align: center;
    background: linear-gradient(147.72deg, #7E7E7E 13.49%, #686868 87.54%);
}

.broadcast-card .card-icon.recipent {
    background: linear-gradient(147.72deg, #7E7E7E 13.49%, #686868 87.54%);
}

.broadcast-card .card-icon.sent {
    background: linear-gradient(158.68deg, #E29334 9.25%, #D68A30 86.48%);
}

.broadcast-card .card-icon.delivered {
    background: linear-gradient(158.68deg, #54AFF1 9.25%, #608DE6 86.48%);
}

.broadcast-card .card-icon.read {
    background: linear-gradient(140.11deg, #33C2B4 14.87%, #2EC18B 85.32%);
}

.broadcast-card .card-icon.failed {
    background: linear-gradient(180deg, #F35944 0%, #F24158 100%);
}

/* .broadcast-card .broadcast-body .card-content .number-s {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #000;
    text-align: center;
    text-align: center;
    padding: 10px 15px;
} */

/* .message-send .broadcast-card .broadcast-header span  {
    background: #5FD595;
}

.message-send .broadcast-card .broadcast-body .card-content {
    border: 1px solid #C5F9DD;
} */

.message-send .broadcast-card .card-content .icon-s {
    background-color: #C5F9DD;
}

/* .delivered-message .broadcast-card .broadcast-header span  {
    background: #3691D2;
}

.delivered-message .broadcast-card .broadcast-body .card-content {
    border: 1px solid #C7E8FF;
} */

.delivered-message .broadcast-card .card-content .icon-s {
    background-color: #C7E8FF;
}

/* .read-message .broadcast-card .broadcast-header span  {
    background:  #DF65A0;
}

.read-message .broadcast-card .broadcast-body .card-content {
    border: 1px solid #FFE2F0;
} */

.read-message .broadcast-card .card-content .icon-s {
    background-color: #FFE2F0;
}

/* .replied .broadcast-card .broadcast-header span  {
    background: #2DB1E4;
}

.replied .broadcast-card .broadcast-body .card-content {
    border: 1px solid #D0F2FF;
} */

.replied .broadcast-card .card-content .icon-s {
    background-color: #D0F2FF;
}

/* .failed .broadcast-card .broadcast-header span  {
    background: #E94B42;
}

.failed .broadcast-card .broadcast-body .card-content {
    border: 1px solid #FFD2CF;
} */

.failed .broadcast-card .card-content .icon-s {
    background-color: #FFD2CF;
}

/* .pending .broadcast-card .broadcast-header span  {
    background: #FFBF00;
}

.pending .broadcast-card .broadcast-body .card-content {
    border: 1px solid #FEEFC1;
} */

.pending .broadcast-card .card-content .icon-s {
    background-color: #FEEFC1;
}

/* .message-send .broadcast-card::before {
    background-color: #5FD595;
}

.delivered-message .broadcast-card::before {
    background-color: #3691D2;
}

.read-message .broadcast-card::before {
    background-color: #DF65A0;
}

.replied .broadcast-card::before {
    background-color: #0FA6E0;
}

.failed .broadcast-card::before {
    background-color: #E94B42;
}

.pending .broadcast-card::before {
    background-color: #FFBF00;
} */

.card-row .col {
    flex: 1 1 auto;
    width: auto;
    padding-right: 3px;
    padding-left: 3px;
    margin-bottom: 10px;
}

.template-link a {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #3691D2;
    float: right;
}

.template-link a {
    text-decoration: none;
}

.template-link a:hover {
    text-decoration: underline;
}

ul.nav li .table-btn {
    width: 30px;
    height: 28px;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: transparent;
    margin: 10px 5px;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    padding: 1px;
}

ul.nav li .table-btn:hover, 
ul.nav li .table-btn.active {
    background: #FFFFFF;
    border: 1px solid #165E5B;
    color: var(--bg-primary);
}

ul.nav li .pagegap {
    color: #C2C2C2;
    font-size: 25px;
    padding: 1px;
    width: 30px;
    height: 28px;
    text-align: center;
    border: 1px solid transparent;
    background-color: transparent;
    margin: 10px 5px;
    line-height: 12px;
}

.css-b62m3t-container .css-13cymwt-control, 
.css-b62m3t-container .css-t3ipsp-control {
    background-color: rgba(232, 239, 239, 0.5);
    padding: 0px 8px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    min-height: 40px !important;
    border-color: transparent !important;
}

.css-1xc3v61-indicatorContainer {
    padding: 0 !important;
}

.broadcast-top .c-select.css-b62m3t-container .css-13cymwt-control, 
.broadcast-top .css-b62m3t-container .css-t3ipsp-control, 
.broadcast-top .template-header-mob .c-select.css-b62m3t-container .css-13cymwt-control, 
.broadcast-top .css-b62m3t-container .css-t3ipsp-control {
    background-color: #fff;
    border: 1px solid #D9D9D9 !important;
    cursor: pointer;
    width: 170px;
    height: 38px;
    box-shadow: none !important;
}

.broadcast-name {
    width: 100%;
    max-width: 200px;
    color: #000000;
}

.broadcast-name .event-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.62px;
}

.broadcast-name .createdby {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.broadcast-type {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #000;
}

.broadcast-type .type-name {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 3px;
}

.broadcast-type .type-name img {
    display: none;
}

.broadcast-type.scheduled .type-name img.scheduled-img {
    display: block;
}

.broadcast-type.instant .type-name img.instant-img {
    display: block;
}

.delivery-status {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: center;
}

.delivery-status .status-span .status-progress {
    width: 70px;
    height: 10px;
    border-radius: 10px;
    border: 1px solid;
    margin-bottom: 5px;
}

.delivery-status .status-span .status-progress .inner {
    border-radius: 10px;
    height: 100%;
    transition: width 0.3s ease;
}

.delivery-status .status-span .status-progress.completed {
    border-color: #B8F1D2;
    background-color: #E9FFF3;

}

.delivery-status .status-span .status-progress.completed .inner {
    background-color: #5FD495;
}

.delivery-status .status-span .status-progress.pending {
    border-color: #FCD872;
    background-color: #FFF2CD;

}

.delivery-status .status-span .status-progress.pending .inner {
    background-color: #FEC931;
}

.delivery-status .status-span .status-progress.failed {
    border-color: #FFB7B2;
    background-color: #E94B421A;

}

.delivery-status .status-span .status-progress.failed .inner {
    background-color: #FFB7B2;
}

.delivery-status .status-span .status-title {
    font-size: 12px;
    margin-bottom: 8px;
}

.delivery-status .status-span .progress-value {
    font-size: 12px;
    font-weight: 500;
    line-height: 15.96px;
    color: #000;
}

.details-search .c-select.css-b62m3t-container .css-13cymwt-control {
    background-color: #fff;
    border: 1px solid #D9D9D9 !important;
    cursor: pointer;
    height: 38px;
    box-shadow: none !important;
}

.details-search .broadcast-heading {
    font-size: 20px;
    font-weight: 500;
    line-height: 26.6px;
    color: #000000;
}

.details-search .broadcast-heading span {
    color: #4B4A4A;
    font-weight: 400;
}

.message-preview-sec {
    box-shadow: 0px 1px 1px 0px #0000001A;
    background-color: #EFEAE2;
    border-radius: 8px;
    padding: 16px 30px 36px 30px;
    background-image: url('../../images/broadcast/preview-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.message-preview-sec .message-preview-title {
    width: 100%;
    text-align: center;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.28px;
    margin-bottom: 10px;
}

.message-preview-sec .message-prreview-inner {
    background-color: #fff;
    padding: 12px 24px 30px 24px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.62px;
    color: #4B4A4A
}

.message-preview-sec .message-prreview-inner::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-top: 15px solid #fff;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    left: -16px;
}

.message-preview-sec .message-prreview-inner .start-heading {
    letter-spacing: -0.3px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
}

.message-preview-sec .message-prreview-inner .message-details {
    margin-bottom: 25px;
    height: auto;
    max-height: calc(100vh - 80vh);
    overflow-y: auto;
    line-height: 23px;
}

.message-preview-sec .message-prreview-inner .regard-text {
    font-weight: 600;
    margin-bottom: 16px;
}

.username-broadcastmob ul li:first-child,
.username-broadcastmob ul li .random-clr {
    display: none !important;
}

.username-broadcastmob ul li .user-email {
    display: none;
}

.username-broadcastmob ul li .c-name {
    font-weight: 400 !important;
    color: #000 !important;
}

@media (max-width: 1200px) {
    .card-row .col {
        flex: 0 0 auto;
        width: 33.33%;
    }
}

@media (max-width: 992px) {
    .card-row .col {
        flex: 0 0 auto;
        width: 50%;
    }

    .signal-btn {
        float: right !important;
    }
}

@media (max-width: 767px) {
    .broadcast-sec .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .broadcast-top ul li {
        width: 100%;
    }

    .broadcast-top .c-select.css-b62m3t-container .css-13cymwt-control, 
    .css-b62m3t-container .css-t3ipsp-control, 
    .template-header-mob .c-select.css-b62m3t-container .css-13cymwt-control, 
    .css-b62m3t-container .css-t3ipsp-control {
        width: 100% !important;
    }

    .broadcast-sec .card-row, 
    .broadcast-sec .details-search .row.ds-row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}