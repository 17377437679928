
:root{
    --form-link-color: #165E5B;
}

.wahtsapp_form {
    background-color: var(--body-bg);
    background-image: url('../../images/backgrounds/form-bg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 72px;
    padding-bottom: 72px;
    height: 100%;
    min-height: calc(100vh - 57px);
}

.wahtsapp_form .form-sec {
    box-shadow: 0px 20px 43px rgba(22, 94, 91, 0.16);
    border-radius: 12px;
    background-color: var(--bg-white);
    padding: 24px 55px;
    margin: auto auto;
    font-family: Segoe UI;
    max-width: 450px;
    margin: auto;
}

.wahtsapp_form .form-sec .form-heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 30px;
}

.wahtsapp_form .form-sec form .form-group {
    margin-bottom: 16px;
}

.wahtsapp_form .form-sec form label {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
    margin-bottom: 6px;
}

.wahtsapp_form .form-sec form .form-control {
    background: #FFFFFF;
    border: 1px solid rgba(22, 94, 91, 0.2);
    border-radius: 5px;
    height: 40px;
    line-height: 40px;
    font-weight: 400;
    font-size: 14px;
}

.wahtsapp_form .form-sec form a {
    color: var(--form-link-color);
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
    margin-bottom: 10px;
}

.wahtsapp_form .form-sec form .last-pra {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #595959;
    margin-bottom: 35px;
}

.wahtsapp_form .form-sec form .last-pra a {
    color: var(--form-link-color);
    text-decoration: underline;
}

.wahtsapp_form .form-sec form .btn-main {
    width: 100%;
    border-radius: 5px;
}

@media (max-width: 767px) {
    .wahtsapp_form {
        height: auto;
        min-height: calc(100vh - 45px);
    }

    .wahtsapp_form .form-sec {
        padding: 20px
    }
}