.navbar-end-btn {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.12) 100%);
    border-radius: 5px;
    padding: 10px 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    margin-left: auto;
    color: #fff;
    text-decoration: none;
}

.navbar-end-btn:hover {
    background: var(--bg-primary);
    color: #fff;
}

.Navbar-top {
    background-color: var(--bg-primary);
    padding-top: 10px;
    padding-bottom: 10px;
}

.Navbar-top a.navbar-brand {
    color: var(--bg-primery-text);
}

.Navbar-top .navbar-nav li {
    position: relative;
}

.Navbar-top .navbar-nav li a {
    color: var(--bg-primery-text);
    padding: 15px 20px;
    border-radius: var(--default-radius);
    margin-right: 10px;
    margin-left: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}

.Navbar-top .navbar-nav li a img {
    margin-right: 10px;
}

.Navbar-top .navbar-nav li a:hover, 
.Navbar-top .navbar-nav li a.active {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.18) 100%);
}

.Navbar-top .navbar-nav li.wd-links {
    background-image: url('../../images/icons/dropdown-icons.svg');
    background-repeat: no-repeat;
    background-position: center right 25px ;
    background-size: inherit;
}

.Navbar-top .navbar-nav li.wd-links a {
    padding-right: 30px;
}

.Navbar-top .navbar-nav li .dropdown-links {
    position: absolute;
    top: 100%;
    left: 0px;
    background-color: transparent;
    padding-top: 15px;
    padding-left: 30px;
    z-index: 999;
    display: none;
}

.Navbar-top .navbar-nav li .dropdown-links .dropdown-links-body {
    background-color: #165E5B;
    border-radius: 5px;
    width: 210px;
    height: auto;
    overflow: hidden;
}

.Navbar-top .navbar-nav li .dropdown-links li {
    padding: 0;
    list-style-type: none;
    padding-inline-start: 0;
}

.Navbar-top .navbar-nav li .dropdown-links li a {
    justify-content: flex-start;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    border-bottom: 1px solid #FFFFFF1A;
    padding: 15px 20px !important;
}

.Navbar-top .navbar-nav li.wd-links:hover .dropdown-links {
    display: block;
}

.profile-btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    padding: 12px 30px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.profile-btn .creat-dn {
    margin-left: 10px;
    position: relative;
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 5px solid #fff;
    border-bottom: 5px solid transparent;
}

.profile-btn:hover {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.18) 100%);
}

.profile-btn span.p-img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: url('../../images/icons/profile-bg.svg') no-repeat center;
    background-size: 100%;
    margin-right: 6px;
}

.profile-btn span.p-img img {
    width: 22px;
    height: auto;
    margin-right: 10px;
}

.Navbar-top .navbar-toggler:focus, 
.Navbar-top .navbar-toggler:active {
    box-shadow: none;
    outline: none;
}

.profile-offcanvas .offcanvas-header {
    background: #F0F2F5;
    padding: 36px 18px;
}

.profile-offcanvas .offcanvas-header .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background-size: 10px;
    border: 1.5px solid #165E5B;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    opacity: 1;
    box-sizing: border-box;
}

.profile-offcanvas .offcanvas-header .btn-close:hover {
    background-color: var(--bg-primary);
    background-image: url('../../images/icons/white-cross.svg');
}

.profile-offcanvas .offcanvas-header .profile-info ul li:first-child {
    padding-right: 30px;
}

.profile-offcanvas .offcanvas-header .profile-info .profile-member {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    position: relative;
    background-color: var(--bg-primary);
    background-image: url('../../images/icons/edit-profile-bg.svg');
    background-position: center;
    background-repeat: no-repeat;
}

.profile-offcanvas .offcanvas-header .profile-info .profile-member img {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-offcanvas .offcanvas-header .profile-info .profile-member .file-upload {
    width: 30px;
    height: 30px;
    background: var(--bg-primary);
    border: 2px solid #F0F2F5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    bottom: 0;
    right: 0;
}

.profile-offcanvas .offcanvas-header .profile-info .profile-member .file-upload i {
    position: relative;
    transform: rotate(40deg);
}

.profile-offcanvas .offcanvas-header .profile-info .profile-member .file-upload input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.profile-offcanvas .offcanvas-header .profile-info .profile-member-details h1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    margin-bottom: 7px;
}

.profile-offcanvas .offcanvas-header .profile-info .profile-member-details p {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 20px;
}

.profile-offcanvas .offcanvas-header .profile-info .profile-member-details .btn {
    padding: 11px 15px;
}

.profile-offcanvas .offcanvas-body {
    padding: 35px 30px;
    position: relative;
}

.profile-offcanvas .offcanvas-body .subcription-card {
    padding: 20px 20px;
    background-color: var(--bg-primary);
    border-radius: 5px;
    background-image: url('../../images/backgrounds/subcription-card-bg.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    margin-bottom: 30px;
}

.profile-offcanvas .offcanvas-body .subcription-card h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 5px;
    color: #fff;
}

.profile-offcanvas .offcanvas-body .subcription-card h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    margin-bottom: 25px;
}

.profile-offcanvas .offcanvas-body .subcription-card h3 span {
    color: #D7DADD;
    font-weight: 400;
}

.profile-offcanvas .offcanvas-body .subcription-card .upgrade-btn {
    background-color: #fff;
    border-color: #fff;
    color: var(--bg-primary);
    padding: 10px 15px;
}

.profile-offcanvas .offcanvas-body .form-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000;
}

.profile-offcanvas .offcanvas-body .form-select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    height: 40px;
    padding: 10px 18px;
    line-height: 16px;
    font-weight: 400;
    font-size: 15px;
    color: rgba(73, 73, 73, 0.7);
    background-image: url('../../images/backgrounds/select-ddbg.svg');
    background-size: 10px;
    position: relative;
}

.profile-offcanvas .offcanvas-body .logout-btn {
    padding: 40px 0px 30px 0px;
    left: 0;
    bottom: 0;
    width: 100%;
}

.notification-btn {
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
    color: #FFFFFF;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    position: relative;
    cursor: pointer;
}

.notification-btn .notification-badge {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 18px;
    border-radius: 5px;
    background-color: #F94F4F;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
}

.offcanvas.notification-offcanvas {
    width: 440px !important;
    max-width: 100%;
}

.notification-offcanvas .offcanvas-header {
    padding: 24px;
    border-bottom: 1px solid #D0D0D080;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.notification-offcanvas .offcanvas-header .header-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #1E293B;
}

.notification-offcanvas .offcanvas-header .allread-btn {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 5px 10px;
    color: #2E70E8;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    position: absolute;
    top: 50%;
    right: 50px;
    width: auto;
    height: auto;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    cursor: pointer;
}

.notification-offcanvas .offcanvas-header .btn-close {
    opacity: 1;
    background-size: 12px;
}

.notification-offcanvas .offcanvas-body .notification-listing .notification-row {
    padding: 0px 0px 0px 24px;
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.notification-offcanvas .offcanvas-body .notification-listing .notification-row:hover, 
.notification-offcanvas .offcanvas-body .notification-listing .notification-row.new {
    background-color: #E9F2FF;
}

.notification-offcanvas .offcanvas-body .notification-listing .notification-row.new::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #F94F4F;
}

.notification-offcanvas .offcanvas-body .notification-listing .notification-row .notification-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #E9F2FF;
    background-image: url('../../images/logos/bell-ic.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: inherit;
}

.notification-offcanvas .offcanvas-body .notification-listing .notification-row:hover .notification-icon {
    background-color: #fff;
}

.notification-offcanvas .offcanvas-body .notification-listing .notification-row .notification-icon.notification-user {
    background-color: #165E5B;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.notification-offcanvas .offcanvas-body .notification-listing .notification-row .notification-content {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 75px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #334155;
    border-bottom: 1px solid #E2E8F0;
    width: 100%
}

.notification-offcanvas .offcanvas-body .notification-listing .notification-row .notification-content span {
    font-weight: 600;
}

.notification-offcanvas .offcanvas-body .notification-listing .notification-row .time {
    font-size: 12px;
    font-weight: 400;
    color: #475569;
    line-height: 18px;
    position: absolute;
    top: 20px;
    right: 30px;
}

.notification-offcanvas .offcanvas-body .notification-listing .notification-row:last-child .notification-content {
    border-bottom: 0px;
}

.notification-offcanvas .offcanvas-body .notification-empty {
    display: none;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.notification-offcanvas.empty .offcanvas-body .notification-empty {
    display: flex;
}

.notification-offcanvas .offcanvas-body .notification-empty .empty-icon-bell {
    margin-bottom: 20px;
}

.notification-offcanvas .offcanvas-body .notification-empty .empty-content {
    color: #1E293B;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.notification-offcanvas .offcanvas-body .notification-empty .empty-content .empty-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
}

.notification-offcanvas.empty .offcanvas-body .notification-listing {
    display: none;
}

.notification-offcanvas.empty .offcanvas-header .allread-btn {
    display: none;
}

@media (max-width: 1200px) {
    .Navbar-top .navbar-nav li a { 
        padding: 15px 10px;
    }
}


@media (max-width: 992px) {
    .desktop-btn {
        display: none !important;
    }

    .Navbar-top .navbar-nav li a {
        justify-content: start;
        border-bottom: 1px solid #fff;
        border-radius: 0px;
        padding: 10px 10px;
    }

    .Navbar-top .navbar-nav li:first-child a {
        border-top: 1px solid #fff;
    }

    .Navbar-top .navbar-nav li:last-child a {
        border-bottom: 0px;
    }

    .Navbar-top .navbar-toggler {
        border: none;
        border-radius: 5px;
        color: #fff;
        padding: 5px;
        font-size: 30px;
    }

    .profile-btn {
        padding: 15px 10px;
    }
}

@media (max-width: 767px) {
    .profile-offcanvas .offcanvas-header {
        background: #F0F2F5;
        padding: 26px 15px;
    }

    .profile-offcanvas .offcanvas-header .profile-info ul li:first-child {
        padding-right: 15px;
    }

    .profile-offcanvas .offcanvas-header .profile-info .profile-member, 
    .profile-offcanvas .offcanvas-header .profile-info .profile-member img {
        width: 50px;
        height: 50px;
        background-size: 25px;
    }

    .profile-offcanvas .offcanvas-header .profile-info .profile-member-details .btn, 
    .profile-offcanvas .offcanvas-body .subcription-card .upgrade-btn {
        padding: 5px 12px;
        font-size: 14px;
    }

    .profile-offcanvas .offcanvas-body {
        padding: 25px 20px;
    }

    .profile-offcanvas .offcanvas-body .subcription-card {
        padding: 15px;
    }
}

@media (max-width: 580px) {
    .offcanvas.notification-offcanvas {
        width: 95% !important;
    }
}

@media (max-width: 350px) {
    .Navbar-top .navbar-brand img {
        width: 100px;
    }
}