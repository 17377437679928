

.listing-table tr td .action ul li, 
.template-accordion-body .action ul li {
    padding: 0px 3px;
}

.listing-table tr td .action ul li .action-b, 
.template-accordion-body .action ul li .action-b {
    width: 32px;
    height: 29px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.listing-table tr td .action ul li .action-b:hover,
.template-accordion-body .action ul li .action-b:hover {
    background: #E9F2FF;
    border-color: #E9F2FF;
}

.listing-table tr td .action ul li .action-b:hover svg path,
.template-accordion-body .action ul li .action-b:hover svg path {
    stroke: #000 !important;
    fill-opacity: 1 !important;
}

.listing-table tr td .action ul li .action-b:hover svg.not-stroke path,
.template-accordion-body .action ul li .action-b:hover svg.not-stroke path  {
    stroke: transparent !important;
}