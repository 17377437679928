

.listingfooter {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px -3px 5px 0px #00000014;
    padding-top: 11px;
    padding-bottom: 11px;
    position: fixed;
    bottom: 0;
    left: 0;
}

.listingfooter ul li, 
.listingfooter ul li a {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    padding: 6px 10px;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;
    margin: 0px 5px;
}

.listingfooter ul li:hover, 
.listingfooter ul li a:hover, .listingfooter ul li.active, .listingfooter ul li a.active {
    color: #165E5B;
    border-color: #165E5B;
}

.listingfooter ul li.prev:hover, 
.listingfooter ul li.next:hover {
    color: #000;
    border-color: transparent;
}