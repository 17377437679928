

.contact-group-table {
    padding-top: 10px;
    padding-bottom: 50px;
}

.contact-group-table .table-responsive {
    border-radius: var(--default-radius);
}

.cg-custom-table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.cg-custom-table .table {
    margin-bottom: 0;
}

.cg-custom-table tr:last-child td {
    border: none;
}

.cg-custom-table tr th {
    background: #E8EFEF;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000;
    padding: 18px 30px;
    text-align: left;
    border: none;
    white-space: nowrap;
}

.cg-custom-table tr td {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    padding: 16px 30px;
    text-align: left;
    vertical-align: middle;
    border-color: rgba(0, 0, 0, 0.1) !important;
}

.cg-custom-table tr td:last-child {
    white-space: nowrap;
}

.cg-custom-table tr td ul {
    flex-wrap: nowrap;
}

.cg-custom-table tr td ul li {
    padding-right: 15px;
}

.cg-custom-table tr td ul li .random-clr {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #fff;
    text-transform: uppercase;
    background: #4860B5;
}

.cg-custom-table tr td .ded-btn {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 32px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    float: left;
}

.cg-custom-table tr td .ded-btn:hover {
    background: #E9F2FF;
    border-color: #E9F2FF;
}

.cg-custom-table tr td .ded-btn:hover svg path {
    stroke: #000 !important;
    fill-opacity: 1 !important;
}

.cg-custom-table tr td .ded-btn:hover svg.not-stroke path {
    stroke: transparent !important;
}

@media (max-width:768px) {

    .cg-custom-table tr td ul li .random-clr {
        width: 22px;
        height: 22px;
        font-size: 13px;
        line-height: 18px;
    }

    .cg-custom-table tr th {
        font-size: 14px;
        padding: 10px 10px;
    }

    .cg-custom-table tr td {
        font-size: 14px;
        padding: 8px 10px;
    }
}