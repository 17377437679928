


.template-header {
    background-color: #fff;
    box-shadow: 0px 3px 5px 0px #00000014;
}

.template-header .template-heading {
    font-weight: 700;
    line-height: 30px;
}

.template-header .form-group .form-control {
    border: 1px solid #D9D9D9;
    height: 38px;
    line-height: 38px;
    padding: 10px 15px;
    font-weight: 400;
    font-size: 14px;
    color: #494949;
}

.template-header .c-select.css-b62m3t-container .css-13cymwt-control, .css-b62m3t-container .css-t3ipsp-control, 
.template-header-mob .c-select.css-b62m3t-container .css-13cymwt-control, .css-b62m3t-container .css-t3ipsp-control {
    background-color: #fff;
    border: 1px solid #D9D9D9 !important;
    cursor: pointer;
    height: 38px;
    box-shadow: none !important;
}