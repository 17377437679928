

.overview-header {
    padding: 8px 24px !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000;
}

.overview-header .date-side {
    display: flex;
    align-items: center;
}

.overview-header .date-side img {
    margin-right: 10px;
    width: 18px;
}

.overview-header ul li {
    background-color: transparent;
    color: #000;
    padding: 5px 30px;
    margin: 0px 5px;
    font-weight: 500;
    cursor: pointer;
}

.overview-header ul li.active, 
.overview-header ul li:hover {
    background-color: var(--bg-primary);
    color: #fff;
}

.company-card .broadcast-card {
    width: 100%;
    max-width: 245px;
    margin: auto;
    margin-bottom: 20px;
}

.company-listing-table tr td .form-switch .form-check-input {
    width: 49px !important;
    border-radius: 50px;
    height: 26px;
    background-color: #E94B42;
    border: 1px solid #E94B42;
    background-image: url('../../images/company/dactive-bg.svg');
    cursor: pointer;
}

.company-listing-table tr td .form-switch .form-check-input:checked {
    background-color: #50A576;
    border: 1px solid #50A576;
    background-image: url('../../images/company/active-bg.svg');
}

@media (max-width:992px) {
    .overview-header ul li {
        font-size: 13px;
        padding: 3px 20px;
        margin: 0 3px;
    }
}

@media (max-width: 767px) {
    .company-card .broadcast-card {
        max-width: 100%;
    }
}

@media (max-width: 500px) {
    .overview-header ul li {
        padding: 3px 15px;
    }

    .contact-group-table .add-company {
        font-size: 13px;
        padding: 6px 15px;
    }
}

@media (max-width: 400px) {
    .overview-header ul li {
        padding: 3px 10px;
        font-size: 12px;
    }
}