

.chatUs {
    position: fixed;
    right: 40px;
    bottom: 100px;
    width: 370px;
    height: auto;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 4px 20px 0px #0000001F;
    overflow: hidden;
    transition: width 200ms ease 0s, 
                height 200ms ease 0s, 
                max-height 200ms ease 0s, 
                transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, 
                opacity 83ms ease-out 0s;
    transform: scale(0);
    opacity: 0;
    transform-origin: right bottom;
}

.chatUs.open {
    opacity: 1;
    transform: scale(1);
}

.chatUs .chatus-header {
    background-color: #2ABC65;
    color: #fff;
    border-radius: 5px 5px 0px 0px;
    padding: 25px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.chatUs .chatus-header .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.chatus-header .close-chat {
    background-color: transparent;
    border: none;
    padding: 5px;
    background-image: url('../../images/close-chat.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: inherit;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.chatus-header .close-chat:hover,
.chatus-header .close-chat:focus, 
.chatus-header .close-chat:active {
    background-color: transparent !important;
    border: none;
    outline: 0;
    box-shadow: none;
}

.chatUs .chatus-body {
    padding: 16px 18px;
    background-image: url('../../images/backgrounds/Rectangle.png');
    display: flex;
    /* height: calc(100vh - 490px); */
    height: min(350px, 100vh - 450px);
    max-height: 350px;
    min-height: 100px;
    overflow-y: auto;
    flex-direction: column;
}

.chatUs .chatus-body .chat-question {
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 14px;
    box-shadow: 0px 0px 15px 0px #0000001A;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    line-height: 22px;
    cursor: pointer;
}

.chatUs .chatus-body .chat-question:hover {
    box-shadow: 0px 0px 8px 0px #30303054;
}

.chatUs .chatus-body::-webkit-scrollbar {
    width: 5px;
}

.chatUs .chatus-body::-webkit-scrollbar-thumb {
    background: #888;
}

.chatUs .chatus-footer {
    background-color: #fff;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
    padding: 10px 20px;
    font-weight: 500;
    color: #000;
    font-size: 12px;
    line-height: 22px;
}

.btn-chatwithUs {
    background-color: #2ABC65;
    box-shadow: 0px 4px 20px 0px #00000033;
    border-radius: 8px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    padding: 8px 20px;
    position: fixed;
    bottom: 30px;
    right: 40px;
    width: auto;
    height: auto;
    border-color: #2ABC65;
}

.btn-chat-close {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2ABC65;
    position: fixed;
    bottom: 30px;
    right: 40px;
    border: none;
    box-shadow: 0px 4px 20px 0px #00000033;
}

.btn-chatwithUs:hover, 
.btn-chat-close:hover {
    background-color: #165E5B !important;
    color: #fff !important;
}

.btn-chatwithUs:focus, 
.btn-chatwithUs:active, 
.btn-chat-close:focus,
.btn-chat-close:active {
    background-color: #2ABC65 !important;
    color: #fff !important;
    box-shadow: none;
    border-color: #2ABC65 !important;
}

@media (max-width: 767px) {
    .chatUs, .btn-chat-close, .btn-chatwithUs {
        right: 10px;
    }

    .newBtn-row {
        border: 1px solid #7777774D;
        position: relative;
        padding: 30px 15px 20px 15px;
        margin-bottom: 20px;
        border-radius: 5px;
        margin-right: 0;
        margin-left: 0;
    }

    .newBtn-row .button-close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: auto;
    }
}

@media (max-width: 400px) {
    .chatUs {
        width: 93%;
        right: 15px;
        transform: translateX(-50%);
     }
}